<template>
    <div class="flex gap-3">
        <base-button
            variant="default"
            icon="line-icons:communication:mail-01"
            size="sm"
            :label="$t('documentExtraction.list.button.viaEmail')"
            @click="sendDocState.show = true"
            v-if="$acl.can('create_document_extractions')"
        />
        <base-button
            variant="primary"
            icon="line-icons:general:upload-01"
            size="sm"
            :label="$t('documentExtraction.list.button.uploadDocuments')"
            @click="toggleUploadDocuments"
            v-if="$acl.can('create_document_extractions')"
        />
    </div>

    <document-extraction-upload-documents
        @close="toggleUploadDocuments"
        :show="state.showUploadDocuments"
    />

    <document-extraction-send-doc-by-email-modal
        :show="sendDocState.show"
        @modal-close="sendDocState.show = false"
    />
</template>
<script setup>
const state = reactive({
    showUploadDocuments: false,
})

const sendDocState = reactive({
    show: false,
})

const toggleUploadDocuments = () => {
    state.showUploadDocuments = !state.showUploadDocuments
}
</script>
