import { DOCUMENT_STATUS } from '@tenant/modules/tenant/document-extraction/utils/constants'

export const getStatusVariant = (status) => {
    switch (status) {
        case DOCUMENT_STATUS.READY:
            return 'success'
        default:
            return 'gray'
    }
}

export const getTextClass = (decreased) => {
    return decreased ? 'text-success-600' : 'text-danger-500'
}

export const getIconName = (decreased) => {
    return decreased
        ? 'line-icons:arrows:arrow-narrow-down-right'
        : 'line-icons:arrows:arrow-narrow-up-right'
}
