<template>
    <base-sticky-heading
        :title="$t('documentExtraction.list.documentsReady')"
        class="min-h-[82px] items-center"
    >
        <document-extraction-grid
            ref="gridRef"
            :statuses="[DOCUMENT_STATUS.READY]"
            edit-route="document-extraction.ready.edit"
            @on-items-selected="onItemSelected"
        >
            <template #grow>
                <div class="flex gap-2.5">
                    <base-button
                        size="sm"
                        :disabled="state.selectedItems.length === 0"
                        @click="addToPurchases"
                        v-if="$acl.can('create_bills')"
                    >
                        {{
                            $t('documentExtraction.list.button.addToPurchases')
                        }}
                    </base-button>
                    <base-button
                        size="sm"
                        variant="default"
                        :disabled="state.selectedItems.length === 0"
                        @click="moveToReview"
                        v-if="$acl.can('update_document_extractions')"
                    >
                        {{ $t('documentExtraction.list.button.moveToReview') }}
                    </base-button>
                    <base-button
                        size="sm"
                        variant="default"
                        :disabled="state.selectedItems.length === 0"
                        @click="onArchive"
                        v-if="$acl.can('update_document_extractions')"
                    >
                        {{ $t('general.archive') }}
                    </base-button>
                </div>
            </template>
        </document-extraction-grid>
    </base-sticky-heading>

    <document-extraction-archive-modal
        :ids="state.archivedIds"
        @modal-close="onCloseArchiveModal"
        @success-archived="onSuccessArchived"
    />

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { DOCUMENT_STATUS } from '@tenant/modules/tenant/document-extraction/utils/constants'
const emitter = useEmitter()

const { t } = useI18n()
const { execute: executeMoveToReview } = useApi(
    '/api/document-extraction/move-to-review',
    'PATCH'
)

useHead({
    title: t('documentExtraction.list.documentsReady'),
})

const gridRef = ref(null)
const state = reactive({
    selectedItems: [],
    archivedIds: null,
})

const onItemSelected = (items) => {
    state.selectedItems = items
}

const moveToReview = async () => {
    try {
        emitter.emit('set-loading', true)

        await executeMoveToReview({
            data: {
                ids: state.selectedItems.map((i) => i.id),
            },
        })

        await gridRef.value.executeRefreshSearch()
    } finally {
        emitter.emit('set-loading', false)
    }
}

// ADD TO PURCHASES
const {
    execute: executeAddToPurchases,
    parameters,
    code,
} = useApi('/api/document-extraction/purchases/add-to', 'POST')

const addToPurchases = async () => {
    try {
        emitter.emit('set-loading', true)

        await executeAddToPurchases({
            data: {
                ids: state.selectedItems.map((i) => i.id),
            },
        })

        await gridRef.value.executeRefreshSearch()
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: ADD TO PURCHASES

const onArchive = () => {
    state.archivedIds = state.selectedItems.map((i) => i.id)
}

const onCloseArchiveModal = () => {
    state.archivedIds = null
}

const onSuccessArchived = () => {
    onCloseArchiveModal()
    gridRef.value.executeRefreshSearch()
}
</script>
