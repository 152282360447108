<template>
    <base-sticky-heading
        :title="$t('documentExtraction.list.documentsArchived')"
        class="min-h-[82px] items-center"
    >
        <data-grid
            table-borderless
            :data-source="entities.data"
            selectable
            :columns="columns"
            :loading="loading"
            identify-field="id"
            @on-items-selected="onItemSelected"
        >
            <template #filter>
                <div class="flex w-full items-center justify-between gap-2">
                    <div class="grow">
                        <div class="flex gap-2.5">
                            <base-button
                                size="sm"
                                variant="default"
                                :disabled="state.selectedItems.length === 0"
                                @click="onUnArchive"
                                v-if="$acl.can('update_document_extractions')"
                            >
                                {{ $t('general.unArchive') }}
                            </base-button>
                            <base-button
                                size="sm"
                                outline
                                variant="danger"
                                :disabled="state.selectedItems.length === 0"
                                @click="onDelete"
                                v-if="$acl.can('delete_document_extractions')"
                            >
                                {{ $t('general.delete') }}
                            </base-button>
                        </div>
                    </div>
                    <div class="flex grow gap-3">
                        <form-text-input
                            size="sm"
                            icon-name="line-icons:general:search-lg"
                            :placeholder="$t('table.searchByName')"
                        >
                            {{ $t('general.search') }}
                        </form-text-input>
                        <document-extraction-filter-slide-out />
                    </div>
                </div>
            </template>

            <template #column-documents="{ item }">
                <div class="flex gap-4">
                    <base-icon
                        :variant="item.flag ? 'primary' : 'inherit'"
                        name="line-icons:maps-&-travel:flag-03"
                        @click.stop="toggleFlag(item)"
                    />
                    <base-icon
                        v-if="item.documents && item.documents.length > 0"
                        variant="inherit"
                        name="line-icons:images:image-01"
                        @click.stop="showPreviews(item.documents)"
                    />
                    <span v-else />
                </div>
            </template>

            <template #column-status>
                <base-badge :label="$t('general.archive')" variant="gray" />
            </template>

            <template #column-user="{ item }">
                <div class="text-sm font-normal text-gray-500">
                    {{ item.created_by.full_name }}
                </div>
            </template>

            <template #column-date="{ item }">
                <div class="text-sm font-normal text-gray-500">
                    {{ $filters.dateHumanFormat(item.date_of_document) }}
                </div>
            </template>

            <template #column-supplier="{ item }">
                {{ getContactName(item.contact) }}
            </template>

            <template #column-amount_gross_total="{ item }">
                {{
                    $filters.currency(
                        item.amount_gross_total,
                        item.currency.iso
                    )
                }}
            </template>

            <template #column-tax="{ item }">
                {{
                    $filters.currency(
                        Math.abs(
                            item.amount_net_total - item.amount_gross_total
                        ),
                        item.currency.iso
                    )
                }}
            </template>

            <template #footer>
                <grid-pagination
                    :total="entities.last_page"
                    :page="entities.current_page"
                />
            </template>
        </data-grid>

        <document-extraction-preview-file
            :preview-files="state.previewFiles"
            @modal-close="removePreviews"
        />
    </base-sticky-heading>
</template>

<script setup>
import map from 'lodash/map'
import { getContactName } from '@tenant/utils/helper'

const { t } = useI18n()
const emitter = useEmitter()

useHead({
    title: t('documentExtraction.list.documentsArchived'),
})

const state = reactive({
    previewFiles: null,
    selectedItems: [],
})

const { useApiSearch, entities } = useApiFactory('document-extraction-archive')
const { loading, executeRefreshSearch } = useApiSearch()

const { errorNotify } = useNotification()
const { execute: executeFlag } = useApi(
    '/api/document-extraction/flag/{id}',
    'PATCH'
)
const { execute: executeUnArchive } = useApi(
    '/api/document-extraction-un-archive',
    'POST'
)
const { execute: executeDelete } = useApi('/api/document-extraction', 'DELETE')

const columns = [
    {
        property: 'documents',
        label: ' ',
    },
    {
        property: 'status',
        label: t('documentExtraction.list.columns.status'),
    },
    {
        property: 'user',
        label: t('documentExtraction.list.columns.user'),
    },
    {
        property: 'date',
        label: t('documentExtraction.list.columns.date'),
    },
    {
        property: 'supplier',
        label: t('documentExtraction.list.columns.supplier'),
    },
    {
        property: 'amount_gross_total',
        label: t('documentExtraction.list.columns.total'),
    },
    {
        property: 'tax',
        label: t('documentExtraction.list.columns.tax'),
    },
]

const toggleFlag = (item) => {
    executeFlag({
        params: {
            id: item.id,
        },
    })
        .then(() => {
            item.flag = !item.flag
        })
        .catch(() => {
            errorNotify({
                title: t('messages.internalServerError'),
            })
        })
}

const onItemSelected = (items) => {
    state.selectedItems = items
}

const showPreviews = (documents) => {
    state.previewFiles = map(documents, 'preview')
}

const removePreviews = () => {
    state.previewFiles = null
}

const onUnArchive = () => {
    emitter.emit('set-loading', true)
    executeUnArchive({
        data: {
            ids: state.selectedItems.map((i) => i.id),
        },
    }).finally(() => {
        emitter.emit('set-loading', false)
        executeRefreshSearch()
    })
}

const onDelete = () => {
    emitter.emit('set-loading', true)
    executeDelete({
        data: {
            ids: state.selectedItems.map((i) => i.id),
        },
    }).finally(() => {
        emitter.emit('set-loading', false)
        executeRefreshSearch()
    })
}
</script>
