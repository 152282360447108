<template>
    <base-sticky-heading>
        <template #left>
            <div class="flex items-center gap-2.5">
                <base-button
                    outline
                    variant="default"
                    size="sm"
                    @click="onBack"
                >
                    <template #content>
                        <span class="flex items-center gap-2">
                            <base-icon
                                name="line-icons:arrows:chevron-left"
                                variant="inherit"
                            />
                            {{ $t('general.back') }}
                        </span>
                    </template>
                </base-button>
                <h5 class="text-xl font-medium text-gray-900">
                    {{ $t('documentExtraction.edit.title') }}
                </h5>
            </div>
        </template>

        <template #right>
            <div class="flex gap-3">
                <base-button
                    variant="default"
                    outline
                    size="sm"
                    @click="onArchive"
                >
                    {{ $t('general.archive') }}
                </base-button>
                <template v-if="result">
                    <slot
                        name="right"
                        v-bind="{
                            result,
                            type,
                            status,
                            handleSubmit,
                            onSave,
                            onConvertTo,
                            onMoveToReady,
                            setErrors,
                            hasErrors,
                        }"
                    />
                </template>
            </div>
        </template>

        <div class="flex h-full w-full bg-gray-50">
            <base-resizable-pane
                class="document-extraction-edit-view"
                @resize="onPanesResize"
                @resized="state.resizing = false"
            >
                <pane size="60" min-size="40">
                    <document-extraction-upload
                        class="h-full py-6 pl-6"
                        :is-resizing="state.resizing"
                    />
                </pane>

                <pane min-size="40" size="40" class="!overflow-auto py-6 pr-6">
                    <div
                        class="min-h-full rounded-lg border border-gray-200 bg-white"
                    >
                        <base-tabs
                            v-if="result"
                            variant="horizontal"
                            class="h-full"
                            :items="tabs"
                            tab-style="underlined"
                            :has-margin="false"
                            tab-class="px-6 border-b border-gray-200 gap-3"
                            tab-item-class="pb-4 pt-5 mr-0 pl-1 pr-1 whitespace-nowrap"
                        >
                            <template #content>
                                <router-view
                                    v-slot="{ Component }"
                                    :result="result"
                                    :multi-line="state.multilineForm"
                                    :old-line-items="oldLineItems"
                                >
                                    <keep-alive>
                                        <component :is="Component" />
                                    </keep-alive>
                                </router-view>
                            </template>
                        </base-tabs>
                    </div>
                </pane>
            </base-resizable-pane>
        </div>
    </base-sticky-heading>

    <document-extraction-archive-modal
        :ids="state.archivedIds"
        @modal-close="onCloseArchiveModal"
        @success-archived="onSuccessArchived"
    />

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="onCloseBookCloseModal"
    />
</template>

<script setup>
import { isEmpty } from 'lodash-es'
import { Pane } from 'splitpanes'
import { shortServerFormat } from '@tenant/utils/day'
import { useValidateDocument } from '@tenant/modules/tenant/document-extraction/composables/use-document-extraction'
import { DOCUMENT_STATUS } from '@tenant/modules/tenant/document-extraction/utils/constants'

const props = defineProps({
    parentRoute: {
        type: String,
        default: null,
    },
    grandRoute: {
        type: String,
        default: null,
    },
})

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const emitter = useEmitter()

const oldLineItems = ref([])

const parentRoute = computed(() => props.parentRoute)

const grandRoute = computed(
    () => props.grandRoute || 'document-extraction.inbox'
)

const hasErrors = computed(() => {
    return !isEmpty(fieldErrors.value)
})

const tabs = computed(() => [
    {
        label: t('documentExtraction.tabs.details'),
        route: `${parentRoute.value}.detail`,
    },
    {
        label: t('documentExtraction.tabs.summary'),
        route: `${parentRoute.value}.summary`,
    },
    {
        label: t('documentExtraction.tabs.protocol'),
        route: `${parentRoute.value}.protocol`,
    },
    {
        label: t('documentExtraction.tabs.lineItems'),
        route: `${parentRoute.value}.line-items`,
    },
])

const state = reactive({
    multilineForm: true,
    archivedIds: null,
    resizing: false,
})

const { generalValidateSchema, lineItemsValidateSchema } = useValidateDocument()

const {
    setValues,
    handleSubmit,
    setErrors,
    errors: fieldErrors,
} = useForm({
    validationSchema: generalValidateSchema(),
})

const { getLineItems } = useItemizeInitialize()
const { useApiGet, useApiSave } = useApiFactory('document-extraction')
const { uploadMultiples } = useUploadStorage()
const { execute: executeGet, result } = useApiGet()
const {
    execute: executeSave,
    code: codeSave,
    parameters: parameterSave,
} = useApiSave()

const {
    execute: executeConvertTo,
    code: codeConvert,
    parameters: parameterConvert,
} = useApi('/api/document-extraction/{id}/convert-to', 'POST')

const status = useFieldValue('status')
const type = useFieldValue('type')

const code = computed(() => {
    return codeSave.value || codeConvert.value
})

const parameters = computed(() => {
    return parameterSave.value || parameterConvert.value
})

const onCloseBookCloseModal = () => {
    codeSave.value = null
    codeConvert.value = null

    parameterSave.value = null
    parameterConvert.value = null
}

onMounted(() => {
    emitter.on('check-field-error', checkFieldError)
    emitter.on('items-changed', onItemsChanged)
    emitter.on('reset-field-error', resetFieldError)
})

onUnmounted(() => {
    emitter.off('check-field-error', checkFieldError)
    emitter.off('items-changed', onItemsChanged)
    emitter.off('reset-field-error', resetFieldError)
})

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        const {
            type,
            status,
            auto_fill,
            itemize,
            contact,
            tax_type,
            contact_id,
            payment_account_id,
            document_number,
            date_of_document,
            due_date,
            currency_id,
            documents,
            line_items,
        } = await executeGet(route.params.id)

        const lineItems = getLineItems(line_items, itemize)

        setValues({
            type,
            status,
            auto_fill,
            itemize,
            contact,
            tax_type,
            contact_id,
            payment_account_id,
            document_number,
            date_of_document: shortServerFormat(date_of_document),
            due_date: shortServerFormat(due_date),
            currency_id,
            documents,
            line_items: lineItems,
        })

        validateLineItems(line_items)
        oldLineItems.value = line_items
    } finally {
        emitter.emit('set-loading', false)
    }
})

const resetFieldError = (field) => {
    setErrors({ [field]: undefined })
}

const onBack = () => {
    router.push({
        name: grandRoute.value,
    })
}

const onPanesResize = (event) => {
    const formSize = event[1]?.size || 0
    state.multilineForm = formSize < 40
    state.resizing = true
}

const onArchive = () => {
    state.archivedIds = [route.params.id]
}

const onCloseArchiveModal = () => {
    state.archivedIds = null
}

const onSuccessArchived = () => {
    onCloseArchiveModal()
    onBack()
}

const onSave = handleSubmit(
    async ({ contact: _, currency: __, documents, ...values }) => {
        try {
            emitter.emit('set-loading', true)

            const uploadKeys = await uploadMultiples(documents)
            await executeSave(
                {
                    ...values,
                    documents: uploadKeys,
                },
                null,
                {
                    method: 'POST',
                }
            )

            await router.push({
                name: grandRoute.value,
            })
        } catch ({ errors }) {
            setErrors(errors)
        } finally {
            emitter.emit('set-loading', false)
        }
    }
)

const onMoveToReady = handleSubmit(
    async ({ contact: _, currency: __, documents, ...values }) => {
        try {
            emitter.emit('set-loading', true)

            const uploadKeys = await uploadMultiples(documents)
            await executeSave(
                {
                    ...values,
                    update_status: DOCUMENT_STATUS.READY,
                    documents: uploadKeys,
                },
                null,
                {
                    method: 'POST',
                }
            )

            await router.push({
                name: grandRoute.value,
            })
        } catch ({ errors }) {
            setErrors(errors)
        } finally {
            emitter.emit('set-loading', false)
        }
    }
)

const onConvertTo = handleSubmit(
    async ({ contact: _, currency: __, documents, ...values }) => {
        try {
            emitter.emit('set-loading', true)

            const uploadKeys = await uploadMultiples(documents)
            await executeConvertTo({
                data: {
                    ...values,
                    documents: uploadKeys,
                },
                params: {
                    id: route.params.id,
                },
            })

            await router.push({
                name: grandRoute.value,
            })
        } catch ({ errors }) {
            setErrors(errors)
        } finally {
            emitter.emit('set-loading', false)
        }
    }
)

const checkFieldError = ({ field, fieldLabel, value }) => {
    if (value) {
        setErrors({ [field]: undefined })
    } else {
        setErrors({ [field]: t('validation.required', { field: fieldLabel }) })
    }
}

const validateLineItems = (line_items) => {
    line_items.forEach((lineItem, idx) => {
        try {
            lineItemsValidateSchema().validateSync(lineItem, {
                abortEarly: false,
            })
        } catch (e) {
            for (const error of e.inner) {
                setErrors({
                    [`line_items.${idx}.${error.path}`]: error.message,
                })
            }
        }
    })
}

const onItemsChanged = ({ lineItems }) => {
    const existsErrors = {}
    for (const [field] of Object.entries(fieldErrors.value)) {
        const match = field.match(/line_items\[(\d+)]/)
        if (!match) {
            continue
        }

        existsErrors[field] = undefined
    }

    setErrors(existsErrors)
    nextTick(() => {
        validateLineItems(lineItems)
    })
}
</script>

<style lang="scss">
.document-extraction-edit-view.base-split {
    &.splitpanes--vertical {
        > .splitpanes__splitter {
            &:before,
            &:after {
                @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
            }
        }
    }
}
</style>
