<template>
    <base-badge
        :variant="totalErrors > 0 ? 'warning' : getStatusVariant(item?.status)"
    >
        <template #label>
            <base-tooltip hover dark placement="right" v-if="totalErrors > 0">
                <div
                    class="flex items-center justify-center gap-1 whitespace-nowrap"
                >
                    {{ $t('documentExtraction.toReview') }}
                    <span
                        class="flex h-4 min-w-4 items-center justify-center rounded-full bg-warning-700 px-0.5 text-xs font-medium text-white"
                    >
                        {{ totalErrors }}
                    </span>
                </div>
                <template #content>
                    <div class="flex flex-col items-start">
                        <span>
                            {{ $t('validation.errorsOccurred', totalErrors) }}
                        </span>
                        <ul class="list-disc pl-3 text-left">
                            <li
                                v-for="(err, idx) in mappingErrors"
                                :key="`general.${idx}`"
                            >
                                {{ err }}
                            </li>
                        </ul>
                    </div>
                </template>
            </base-tooltip>

            <template v-else>
                {{ $t('documentExtractionStatus', { status: item.status }) }}
            </template>
        </template>
    </base-badge>
</template>

<script setup>
import { getStatusVariant } from '@tenant/modules/tenant/document-extraction/utils/helper'
import { useValidateDocument } from '@tenant/modules/tenant/document-extraction/composables/use-document-extraction'

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})

const { getMappingErrors } = useValidateDocument()

const mappingErrors = computed(() => {
    return getMappingErrors(props.item)
})

const totalErrors = computed(() => {
    return mappingErrors.value.length
})
</script>
