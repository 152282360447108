const navigation = [
    {
        id: 'sas-document-extraction',
        path: 'document-extraction',
        label: 'sidebar.documentExtraction.label',
        icon: 'line-icons:education:book-closed',
        position: 40,
        permission: 'view_document_extractions',
    },
    {
        id: 'sas-document-extraction-inbox',
        path: 'document-extraction.inbox',
        label: 'sidebar.documentExtraction.inboxLabel',
        parent: 'sas-document-extraction',
        position: 1,
    },
    {
        id: 'sas-document-extraction-in-processing',
        path: 'document-extraction.in-processing',
        label: 'sidebar.documentExtraction.inProcessingLabel',
        parent: 'sas-document-extraction',
        position: 2,
    },
    {
        id: 'sas-document-extraction-review',
        path: 'document-extraction.review',
        label: 'sidebar.documentExtraction.reviewLabel',
        parent: 'sas-document-extraction',
        position: 3,
    },
    {
        id: 'sas-document-extraction-ready',
        path: 'document-extraction.ready',
        label: 'sidebar.documentExtraction.readyLabel',
        parent: 'sas-document-extraction',
        position: 4,
    },
    {
        id: 'sas-document-extraction-archived',
        path: 'document-extraction.archived',
        label: 'sidebar.documentExtraction.archivedLabel',
        parent: 'sas-document-extraction',
        position: 5,
    },
    {
        id: 'sas-document-extraction-fail',
        path: 'document-extraction.fail',
        label: 'sidebar.documentExtraction.failLabel',
        parent: 'sas-document-extraction',
        position: 5,
    },
]

export default navigation
