const getNamesByProperties = (properties) => {
    return properties.reduce((cur, item, idx) => {
        if (!item.name) {
            return cur
        }

        if (idx === 0) {
            return item.name
        }

        if (idx === properties.length - 1) {
            return `${cur} and ${item.name}`
        }

        return `${cur}, ${item.name}`
    }, '')
}

export default {
    documentExtraction: {
        varianceGrid: {
            name: 'Name',
            difference: 'Difference',
            trend: 'Trend',
        },
        reRunBtn: 'Re-run',
        variance: 'Variance',
        toReview: 'To Review',
        totalSpend: ({ named }) => {
            const amount = named('amount')
            if (amount < 0) {
                return 'Total spend decreased'
            }

            return 'Total spend increased'
        },
        lineItemBreakdown: 'Line item breakdown',
        inTotal: 'in total',
        largestDriver: 'Largest driver of variance',
        differenceIn: ({ named }) => {
            const amount = named('amount')
            const name = named('name')
            if (amount < 0) {
                return `decrease in ${name}`
            }

            return `increase in ${name}`
        },
        lineItemsFor: ({ named }) => {
            const amount = named('amount')
            const count = named('count')
            if (amount < 0) {
                return `${count} line items decreased for`
            }

            return `${count} line items increased for`
        },
        replaceCOATooltip: 'Recommended based on your history.',
        emptyText: 'No document extraction found',
        emptySubText: 'Go ahead an create your first document extraction',
        searchByDocNumber: 'Search by document number',
        publishAs: 'Publish as {name}',
        errorNotification: 'Please resolve these issues before publishing:',
        uploadedBy: 'Uploaded by {name}',
        importViaEmail: 'Import via Email',
        importViaCloud: 'Import via Cloud',
        fieldsWereIdentified: '{count} fields were identified',
        existingContactFound: 'Existing Contact “{name}” was found',
        movedTheDocumentTo: 'moved the document to {status}',
        vendorHistory: 'Vendor history',
        vatWasDetected: ({ named }) => {
            const names = getNamesByProperties(named('properties'))

            return `VAT “${names}” was detected`
        },
        coaWasDetected: ({ named }) => {
            const names = getNamesByProperties(named('properties'))

            return `COA “${names}” was recommended`
        },
        match: {
            description:
                'This item matches an outgoing payment in your bank feed, meaning that this item has been paid. Click "Autofill Payment" so that it can be reconciled easily.',
            bankMatch: 'Bank match',
            bankAccount: 'Bank Account',
            autoFillPayment: 'Autofill Payment',
            undoAutofill: 'Undo autofill',
        },
        archiveModal: {
            title: 'Archive {count} item?',
            content:
                'Are you sure you want to archive this item?|Are you sure you want to archive these item?',
        },
        convertTypes: {
            bill: 'Bill',
            expense: 'Expense',
        },
        tabs: {
            details: 'Details',
            summary: 'Summary',
            protocol: 'Protocol',
            lineItems: 'Line Items',
        },
        edit: {
            title: 'Edit Document Extraction',
            buttonSaveAsDraft: 'Save as draft',
            expenditureDocument: {
                fileUploadDescription: 'PNG, JPG or PDF (max. 800x400px)',
            },
            documentNumber: {
                label: ({ named }) => {
                    const required = named('required')
                    if (required) {
                        return 'Document number *'
                    }

                    return 'Document number'
                },
                placeholder: 'e.g. invoice number',
            },
            documentDate: {
                label: 'Date of document *',
            },
            supplier: {
                label: 'Supplier *',
                placeholder: 'Search supplier',
            },
            type: {
                label: 'Type',
                placeholder: 'Search type',
            },
            dueDate: {
                label: 'Due date',
            },
            paymentAccount: {
                label: 'Payment Account *',
                placeholder: 'Select payment account',
            },
            lineItem: {
                chartOfAccount: {
                    label: 'Chart of Account *',
                    placeholder: 'Select chart of account',
                },
                description: {
                    label: 'Description',
                },
                amount: {
                    label: 'Amount *',
                },
                tax: {
                    label: 'Tax *',
                    placeholder: 'Select a Tax',
                },
            },

            tax: {
                label: 'VAT rule',
                taxRule1: 'VAT rule 1',
                taxRule2: 'VAT rule 2',
                taxRule3: 'VAT rule 3',
                addTaxBtn: 'Add tax rate',
            },
            summary: {
                net: 'Net amount',
                tax: 'VAT',
                total: 'Total',
            },
        },
        list: {
            title: 'Document Extraction',
            documentsArchived: 'Archived Documents',
            documentsToReview: 'Documents to Review',
            documentsReady: 'Documents that are Ready',
            extractionInProcess: 'Extraction in Process',
            failedExtraction: 'Failed extractions',
            hasReviewItemsError:
                'There are items that are still in review. Please fix them before moving to ready.',
            button: {
                moveToReady: 'Move to Ready',
                addToPurchases: 'Add to purchases',
                moveToReview: 'Move to Review',
                viaEmail: 'Via Email',
                uploadDocuments: 'Upload documents',
                addBill: 'Add bill',
            },
            contextMenu: {
                markAsPaid: 'Mark as paid',
                markAsUnPaid: 'Mark as unpaid',
            },
            columns: {
                preview: 'Preview',
                status: 'Status',
                user: 'User',
                date: 'Date',
                supplier: 'Supplier',
                total: 'Total',
                tax: 'Tax',
                id: 'Item ID',
                fileName: 'Filename',
                extractionProcess: 'Extraction process',
            },
            filters: {
                supplier: 'Supplier',
                supplierPlaceholder: 'Search supplier',
                dateOfDocument: 'Date of document',
                payDate: 'Pay date',
                document: 'Document',
                amountNet: 'Amount (Net)',
                flag: 'Flag',
                flagged: 'Flagged',
                unFlagged: 'Unflagged',
            },
            unableToDisplayPDF:
                'Unable to display <a href="{link}" class="text-primary-500">PDF</a> file.',
        },
        uploadDocuments: {
            title: 'Upload bills',
            headingDescription:
                'Use this page to add your bills, bills and purchase invoices',
            perPage: {
                title: 'One document per page',
                description: 'PDF files only (max 2MB)',
                uploadDescription: 'PDF files only (max 2MB)',
            },
            perFile: {
                title: 'One document per file',
                description: 'PDF, PNG or JPG files only (max 2MB)',
                uploadDescription: 'PDF, PNG or JPG files only (max 2MB)',
            },
            uploadSuccessful: 'Upload {count} files successful',
            uploadError: 'An error occurred while uploading files',
            duplicateFileError:
                'This item was rejected as this file already exists within this account.',
            documentProcessed: 'Your documents are being processed.',
            theAutomatically:
                'The documents are now automatically put into progress.',
            viewInProgress: 'View in progress',
            documentsProcessing: 'Document within ID: "{id}" is processing',
            documentsReviewing: 'Document within ID: "{id}" is reviewing',
            documentsFailed: 'Document within ID: "{id}" is failed',
        },

        viaEmailModal: {
            title: 'Email receipts or bills',
            firstDescription:
                'Forward receipts, bills and documents from your mail to',
            secondDescription:
                "We'll scan these receipts, so that you can review and convert them into Bills or Expenses.",
        },

        documentTrainingModal: {
            title: 'Permission to use documents for training',
            alertTitle: 'We want to improve the document extraction',
            alertDescription:
                'Some of your uploaded documents couldn’t be extracted properly due to the complexity of the document.\n\n' +
                'We would like to use these documents to train our AI models to improve the quality of the document extraction.',
            givePermissionLabel: 'I give permission',
            givePermissionDescription:
                'Yes, I agree that you’re allowed to use these documents to train the AI models.',
            dontShowAgainLabel: 'Don’t show again',
        },
    },
}
