<template>
    <base-sticky-heading
        :title="$t('documentExtraction.list.documentsToReview')"
        class="min-h-[82px] items-center"
    >
        <document-extraction-grid
            ref="gridRef"
            :statuses="[DOCUMENT_STATUS.NEW, DOCUMENT_STATUS.VIEWED]"
            edit-route="document-extraction.review.edit"
            selectable
            @on-items-selected="onItemSelected"
            suppress-refresh-loading
        >
            <template #grow>
                <div class="flex gap-2.5">
                    <base-button
                        size="sm"
                        variant="primary"
                        :disabled="state.selectedItems.length === 0"
                        @click="moveToReady"
                        v-if="$acl.can('update_document_extractions')"
                    >
                        {{ $t('documentExtraction.list.button.moveToReady') }}
                    </base-button>
                    <base-button
                        size="sm"
                        variant="default"
                        :disabled="state.selectedItems.length === 0"
                        @click="onArchive"
                        v-if="$acl.can('update_document_extractions')"
                    >
                        {{ $t('general.archive') }}
                    </base-button>
                </div>
            </template>
        </document-extraction-grid>
    </base-sticky-heading>

    <document-extraction-archive-modal
        :ids="state.archivedIds"
        @modal-close="onCloseArchiveModal"
        @success-archived="onSuccessArchived"
    />
</template>

<script setup>
import {
    DOCUMENT_PROCESSING_STATUS,
    DOCUMENT_STATUS,
} from '@tenant/modules/tenant/document-extraction/utils/constants'
import { ECHO_CHANNELS, ECHO_EVENTS } from '@tenant/utils/constants'
import { useValidateDocument } from '@tenant/modules/tenant/document-extraction/composables/use-document-extraction'

const emitter = useEmitter()
const { t } = useI18n()
useHead({
    title: t('documentExtraction.list.documentsToReview'),
})

const state = reactive({
    selectedItems: [],
    archivedIds: null,
})

const gridRef = ref(null)

const { execute: executeMoveToReady } = useApi(
    '/api/document-extraction/move-to-ready',
    'PATCH'
)

useTenantListen(
    ECHO_CHANNELS.GLOBAL,
    ECHO_EVENTS.DOCUMENT_EXTRACTION_UPLOADED,
    ({ status }) => {
        if (status !== DOCUMENT_PROCESSING_STATUS.REVIEW) {
            return
        }

        gridRef.value?.refreshSearch()
    },
    {
        leaveOnUnmount: false,
    }
)

const onItemSelected = (items) => {
    state.selectedItems = items
}

const { errorNotify } = useNotification()
const moveToReady = async () => {
    try {
        const hasReviewItems = state.selectedItems.some(
            (item) => mappingErrors(item).length > 0
        )

        if (hasReviewItems) {
            errorNotify({
                text: t('documentExtraction.list.hasReviewItemsError'),
            })
            return
        }

        emitter.emit('set-loading', true)

        await executeMoveToReady({
            data: {
                ids: state.selectedItems.map((i) => i.id),
            },
        })

        await gridRef.value.executeRefreshSearch()
    } finally {
        emitter.emit('set-loading', false)
    }
}

const onArchive = () => {
    state.archivedIds = state.selectedItems.map((i) => i.id)
}

const onCloseArchiveModal = () => {
    state.archivedIds = null
}

const onSuccessArchived = () => {
    onCloseArchiveModal()
    gridRef.value.refreshSearch()
}

const { getMappingErrors } = useValidateDocument()
const mappingErrors = (item) => {
    return getMappingErrors(item)
}
</script>
