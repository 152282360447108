<template>
    <base-modal
        size="lg"
        close
        @modal-close="closeModal"
        :show="show"
        has-separator
    >
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('documentExtraction.documentTrainingModal.title') }}
            </span>
        </template>

        <base-alert
            variant="primary"
            class="mb-6"
            :title="$t('documentExtraction.documentTrainingModal.alertTitle')"
            :description="
                $t('documentExtraction.documentTrainingModal.alertDescription')
            "
        >
            <template #description="{ classes }">
                <span
                    class="whitespace-pre-line text-sm font-normal"
                    :class="classes"
                >
                    {{
                        $t(
                            'documentExtraction.documentTrainingModal.alertDescription'
                        )
                    }}
                </span>
            </template>
        </base-alert>

        <form-checkbox
            v-model="givePermission"
            class="mb-6"
            :text="
                $t(
                    'documentExtraction.documentTrainingModal.givePermissionLabel'
                )
            "
            :supporting-text="
                $t(
                    'documentExtraction.documentTrainingModal.givePermissionDescription'
                )
            "
        />

        <template #modal-footer>
            <div class="flex items-center justify-between gap-3">
                <form-checkbox
                    v-model="dontShowAgain"
                    class="mb-6"
                    :text="
                        $t(
                            'documentExtraction.documentTrainingModal.dontShowAgainLabel'
                        )
                    "
                />

                <div class="flex gap-3">
                    <base-button variant="default" @click="closeModal">
                        {{ $t('general.cancel') }}
                    </base-button>
                    <base-button variant="primary" @click="onConfirm">
                        {{ $t('general.confirm') }}
                    </base-button>
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    config: {
        type: Object,
        default: () => ({}),
    },
})

const emit = defineEmits(['modal-close', 'modal-confirm'])

const { values } = useForm()
const { value: givePermission } = useField(
    'approvedToUseDocumentsForAITraining',
    undefined,
    {
        initialValue:
            !!props.config?.['system.approvedToUseDocumentsForAITraining']
                ?.value,
    }
)
const { value: dontShowAgain } = useField(
    'doNotShowPermissionToUseDocumentForTrainingModel',
    undefined,
    {
        initialValue:
            !!props.config?.[
                'system.doNotShowPermissionToUseDocumentForTrainingModel'
            ]?.value,
    }
)

const closeModal = () => {
    emit('modal-close')
}

const onConfirm = async () => {
    emit('modal-confirm', values)
}
</script>
