import { GUARD } from '@tenant/utils/constants'

const editViews = (type) => ({
    name: `document-extraction.${type}.edit`,
    path: 'edit/:id',
    component: () => import('./pages/document-extraction-edit.vue'),
    redirect: {
        name: `document-extraction.${type}.edit.detail`,
    },
    children: [
        {
            name: `document-extraction.${type}.edit.detail`,
            path: 'detail',
            component: () =>
                import('./views/document-extraction-edit-view-detail.vue'),
        },
        {
            name: `document-extraction.${type}.edit.summary`,
            path: 'summary',
            component: () =>
                import('./views/document-extraction-edit-view-summary.vue'),
        },
        {
            name: `document-extraction.${type}.edit.protocol`,
            path: 'protocol',
            component: () =>
                import('./views/document-extraction-edit-view-protocol.vue'),
        },
        {
            name: `document-extraction.${type}.edit.line-items`,
            path: 'line-items',
            component: () =>
                import('./views/document-extraction-edit-view-line-items.vue'),
        },
    ],
    meta: {
        permission: 'update_document_extractions',
    },
})

const routes = [
    {
        name: 'document-extraction',
        path: '/document-extraction',
        redirect: {
            name: 'document-extraction.inbox',
        },
        children: [
            {
                name: 'document-extraction.inbox',
                path: 'inbox',
                redirect: {
                    name: 'document-extraction.inbox.list',
                },
                children: [
                    {
                        name: 'document-extraction.inbox.list',
                        path: '',
                        component: () =>
                            import('./pages/document-extraction-inbox.vue'),
                    },
                    editViews('inbox'),
                ],
            },
            {
                name: 'document-extraction.in-processing',
                path: 'processing',
                component: () =>
                    import('./pages/document-extraction-in-processing.vue'),
            },
            {
                name: 'document-extraction.review',
                path: 'review',
                redirect: {
                    name: 'document-extraction.review.list',
                },
                children: [
                    {
                        name: 'document-extraction.review.list',
                        path: '',
                        component: () =>
                            import('./pages/document-extraction-review.vue'),
                    },
                    editViews('review'),
                ],
            },
            {
                name: 'document-extraction.ready',
                path: 'ready',
                redirect: {
                    name: 'document-extraction.ready.list',
                },
                children: [
                    {
                        name: 'document-extraction.ready.list',
                        path: '',
                        component: () =>
                            import('./pages/document-extraction-ready.vue'),
                    },
                    editViews('ready'),
                ],
            },
            {
                name: 'document-extraction.archived',
                path: 'archived',
                component: () =>
                    import('./pages/document-extraction-archive.vue'),
            },
            {
                name: 'document-extraction.fail',
                path: 'fail',
                component: () => import('./pages/document-extraction-fail.vue'),
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        noPadding: true,
        permission: 'view_document_extractions',
    },
}
