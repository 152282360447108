<template>
    <slide-out-menu
        @close="onClose"
        :show="show"
        :title="t('documentExtraction.uploadDocuments.title')"
        :subtitle="t('documentExtraction.uploadDocuments.headingDescription')"
        :loading="state.loading"
        size="sm"
    >
        <base-section vertical hide-info-block>
            <template #right>
                <div class="flex flex-col gap-3">
                    <!--                    @TODO: Will add it again within upload one doc per page-->
                    <!--                    <form-radio-group-item-->
                    <!--                        v-model="state.uploadType"-->
                    <!--                        name="uploadType"-->
                    <!--                        :value="UPLOAD_TYPE.PAGE"-->
                    <!--                    >-->
                    <!--                        <base-feature-icon-->
                    <!--                            name="line-icons:finance-&-ecommerce:credit-card-plus"-->
                    <!--                        />-->
                    <!--                        <div class="ml-3.5">-->
                    <!--                            <p class="block text-sm font-medium text-gray-700">-->
                    <!--                                {{ $t('documentExtraction.uploadDocuments.perPage.title') }}-->
                    <!--                            </p>-->
                    <!--                            <p-->
                    <!--                                class="block text-sm font-normal text-primary-600"-->
                    <!--                            >-->
                    <!--                                {{-->
                    <!--                                    $t(-->
                    <!--                                        'documentExtraction.uploadDocuments.perPage.description'-->
                    <!--                                    )-->
                    <!--                                }}-->
                    <!--                            </p>-->
                    <!--                        </div>-->
                    <!--                    </form-radio-group-item>-->
                    <form-radio-group-item
                        v-model="state.uploadType"
                        name="uploadType"
                        :value="UPLOAD_TYPE.PER_FILE"
                    >
                        <base-feature-icon
                            name="line-icons:finance-&-ecommerce:credit-card-plus"
                        />
                        <div class="ml-3.5">
                            <p class="block text-sm font-medium text-gray-700">
                                {{
                                    $t(
                                        'documentExtraction.uploadDocuments.perFile.title'
                                    )
                                }}
                            </p>
                            <p
                                class="block text-sm font-normal text-primary-600"
                            >
                                {{
                                    $t(
                                        'documentExtraction.uploadDocuments.perFile.description'
                                    )
                                }}
                            </p>
                        </div>
                    </form-radio-group-item>
                </div>
            </template>
        </base-section>
        <base-section v-if="state.uploadType" vertical hide-info-block>
            <template #right>
                <div class="pb-2">
                    <form-file-upload
                        v-if="state.uploadType === UPLOAD_TYPE.PAGE"
                        :description="
                            $t(
                                'documentExtraction.uploadDocuments.perPage.uploadDescription'
                            )
                        "
                        :accepted-files="['.pdf']"
                        :allow-remove="false"
                        @on-upload-files="onUploadFiles"
                    />
                    <form-file-upload
                        v-else
                        :description="
                            $t(
                                'documentExtraction.uploadDocuments.perFile.uploadDescription'
                            )
                        "
                        :accepted-files="['.pdf', '.png', '.jpg', '.jpeg']"
                        :allow-remove="false"
                        @on-upload-files="onUploadFiles"
                    />
                </div>

                <template v-if="result?.duplicateFiles?.length > 0">
                    <base-alert
                        v-for="(file, index) in result.duplicateFiles"
                        :key="index"
                        variant="danger"
                        :title="file"
                        :description="
                            $t(
                                'documentExtraction.uploadDocuments.duplicateFileError'
                            )
                        "
                        class="mb-6"
                    />
                </template>

                <base-alert
                    v-if="success && !result?.duplicateFiles?.length"
                    variant="success"
                    :title="
                        $t(
                            'documentExtraction.uploadDocuments.documentProcessed'
                        )
                    "
                    :description="
                        $t(
                            'documentExtraction.uploadDocuments.theAutomatically'
                        )
                    "
                >
                    <template #after-content>
                        <span
                            class="mt-3 flex cursor-pointer flex-row gap-2 text-sm font-medium text-success-700"
                            @click="goToInProcess"
                        >
                            {{
                                $t(
                                    'documentExtraction.uploadDocuments.viewInProgress'
                                )
                            }}
                            <base-icon
                                name="line-icons:arrows:arrow-right"
                                size="md"
                                variant="inherit"
                            />
                        </span>
                    </template>
                </base-alert>
                <base-alert
                    v-else-if="errorMessage"
                    variant="danger"
                    :title="$t('general.error')"
                    :description="errorMessage"
                />
            </template>
        </base-section>
    </slide-out-menu>
</template>

<script setup>
import { UPLOAD_TYPE } from '@tenant/modules/tenant/document-extraction/utils/constants'

const emit = defineEmits(['close'])
const { t } = useI18n()
const router = useRouter()
const { uploadMultiples } = useUploadStorage()

const state = reactive({
    loading: false,
    uploadType: UPLOAD_TYPE.PER_FILE,
})

const { successNotify, errorNotify } = useNotification()
const { execute, success, errorMessage, result } = useApi(
    '/api/document-extraction/multiple-upload',
    'POST'
)

defineProps({
    show: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const onClose = () => {
    emit('close')
}

const onUploadFiles = async (uploadFiles = []) => {
    if (!uploadFiles || uploadFiles.length === 0) {
        return
    }

    try {
        state.loading = true
        const uploadKeys = await uploadMultiples(uploadFiles)

        await execute({
            data: {
                documents: uploadKeys,
                upload_type: state.uploadType,
            },
        })
    } catch (e) {
        errorNotify({
            title: t('documentExtraction.uploadDocuments.uploadError'),
        })
    } finally {
        if (uploadFiles.length > 0 && !result.value?.duplicateFiles?.length) {
            successNotify({
                title: t(
                    'documentExtraction.uploadDocuments.uploadSuccessful',
                    {
                        count: uploadFiles.length,
                    }
                ),
            })
        }

        state.loading = false
    }
}

const goToInProcess = () => {
    router.push({ name: 'document-extraction.in-processing' })
}
</script>
