<template>
    <base-modal
        close
        :show="show"
        @modal-close="closeModal"
        size="md"
        class="w-[495px]"
    >
        <template #modal-header>
            <p class="text-lg font-medium text-gray-900">
                {{ $t('documentExtraction.viaEmailModal.title') }}
            </p>
        </template>
        <p class="text-sm text-gray-500">
            {{ $t('documentExtraction.viaEmailModal.firstDescription') }}
            <a
                class="text-primary-600 underline"
                :href="`mailto:${mailAddress}`"
            >
                {{ mailAddress }}
            </a>

            <br />
            <br />
            {{ $t('documentExtraction.viaEmailModal.secondDescription') }}
        </p>
    </base-modal>
</template>

<script setup>
import cookie from '@tenant/utils/cookie'
import { STORAGE } from '@tenant/utils/constants'

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['modal-close'])

const closeModal = () => {
    emit('modal-close')
}

const { user } = useAuth()
const mailAddress = computed(() => {
    const tenantId = cookie.getItem(STORAGE.TENANT_ID)
    return `documents+${tenantId}+${user.value.id}@inbox.cybooks.com.cy`
})
</script>
