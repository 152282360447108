<template>
    <on-click-outside @trigger="$emit('change', false)">
        <div
            :style="style"
            @click="$emit('change', true)"
            class="pointer-events-auto absolute cursor-pointer bg-warning-200 opacity-50 outline outline-2 outline-white hover:bg-warning-100 hover:outline-2 hover:outline-warning-400"
        />
    </on-click-outside>
</template>

<script setup>
import { computed } from 'vue'
import { OnClickOutside } from '@vueuse/components'

const props = defineProps({
    bounding: {
        type: Object,
        required: true,
    },
})

const style = computed(() => {
    const { bounding } = props

    return {
        top: `calc(${bounding.top} * 100%)`,
        left: `calc(${bounding.left} * 100%)`,
        height: `calc(${bounding.height} * 100%)`,
        width: `calc(${bounding.width} * 100%)`,
    }
})
</script>
