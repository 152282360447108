<template>
    <div class="p-6">
        <base-alert v-if="hasErrors" variant="warning" class="mb-6">
            <template #title="{ titleClass }">
                <span class="mb-1 text-sm font-medium" :class="titleClass">
                    {{ $t('documentExtraction.errorNotification') }}
                </span>
            </template>
            <template #description="{ classes }">
                <div class="text-sm font-normal" :class="classes">
                    <ul class="list-disc">
                        <li
                            v-for="(err, idx) in mappingErrors"
                            :key="`general.${idx}`"
                        >
                            {{ err }}
                        </li>
                    </ul>
                </div>
            </template>
        </base-alert>
        <document-extraction-general-informations :result="result" />

        <hr class="my-6" />

        <document-extraction-line-items
            :old-line-items="oldLineItems"
            :multi-line="multiLine"
        />

        <hr class="my-6" />

        <!-- Summary -->
        <document-extraction-summary />
    </div>
</template>

<script setup>
import { isEmpty } from 'lodash-es'

defineProps({
    result: {
        type: Object,
        required: true,
    },
    multiLine: {
        type: Boolean,
        default: true,
    },
    oldLineItems: {
        type: Array,
        default: null,
    },
})

const { t } = useI18n()

const fieldErrors = useFormErrors()

const hasErrors = computed(() => {
    return !isEmpty(fieldErrors.value)
})

const mappingErrors = computed(() => {
    const errors = []

    for (const [field, message] of Object.entries(fieldErrors.value)) {
        const match = field.match(/line_items\[(\d+)]/)
        if (!match) {
            errors.push(message)
            continue
        }

        errors.push(t('validation.lineItems'))
    }

    return [...new Set(errors)]
})
</script>
