<template>
    <base-modal
        :show="show"
        close
        size="xxl"
        :show-footer="false"
        :loading="loading"
        @modal-close="closeModal"
    >
        <template #container>
            <div
                class="flex justify-between border-b border-gray-300 px-6 py-5"
            >
                <div class="flex items-center gap-6">
                    <h5 class="text-lg font-medium text-gray-900">
                        {{ $t('general.compare') }}
                    </h5>

                    <document-extraction-edit-variance-date-range
                        @update-range="updateRange"
                    />
                </div>

                <button @click.prevent="closeModal">
                    <base-icon
                        name="line-icons:general:x"
                        size="lg"
                        variant="gray"
                    />
                </button>
            </div>

            <div
                v-if="varianceData && varianceData.length > 0"
                class="flex flex-col"
            >
                <div class="flex">
                    <document-extraction-edit-variance-bar-chart
                        :start-date="startDate"
                        :end-date="endDate"
                        :variance-data="varianceData"
                    />
                    <document-extraction-edit-variance-summary
                        :summary-data="summaryData"
                    />
                </div>

                <document-extraction-edit-variance-grid
                    :start-date="startDate"
                    :end-date="endDate"
                    :variance-data="varianceData"
                />
            </div>

            <div v-else class="flex flex-col items-center justify-center p-6">
                <base-feature-icon
                    class="mb-4"
                    size="lg"
                    variant="primary"
                    name="line-icons:general:search-lg"
                />
                <div class="text-center text-md font-medium text-gray-900">
                    {{ $t('grid.noItem') }}
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { dayjsFormat, shortServerFormat } from '@tenant/utils/day'

const emit = defineEmits(['modal-close'])

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    result: {
        type: Object,
        required: true,
    },
})

const {
    execute,
    loading,
    result: varianceResult,
} = useApi('/api/document-extraction/{id}/variance', 'GET')

const state = reactive({
    startDate: null,
    endDate: null,
})

const varianceData = computed(() => {
    if (!varianceResult.value) {
        return null
    }

    return varianceResult.value.items || []
})

const summaryData = computed(() => {
    if (!varianceResult.value) {
        return null
    }

    return varianceResult.value.summary
})

const startDate = computed(() => {
    if (!state.startDate) {
        return null
    }

    return dayjsFormat(state.startDate, 'MMM YYYY')
})

const endDate = computed(() => {
    if (!state.endDate) {
        return null
    }

    return dayjsFormat(state.endDate, 'MMM YYYY')
})

const updateRange = (range) => {
    state.startDate = range.fromDate
    state.endDate = range.toDate
}

watch(
    () => [startDate.value, endDate.value],
    () => {
        if (!startDate.value || !endDate.value) {
            return
        }

        execute({
            params: {
                id: props.result.id,
            },
            queryParams: {
                start_date: shortServerFormat(startDate.value),
                end_date: shortServerFormat(endDate.value),
            },
        })
    },
    {
        immediate: true,
    }
)

const closeModal = () => {
    emit('modal-close')
}
</script>
