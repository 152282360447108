<template>
    <base-modal
        :show="total > 0"
        close
        size="md"
        :loading="loading"
        @modal-close="closeModal"
    >
        <template #modal-header>
            <div class="flex flex-col items-center justify-center gap-2">
                <h4 class="text-lg font-medium text-gray-900">
                    {{ $t('documentExtraction.archiveModal.title', total) }}
                </h4>

                <h5 class="text-sm font-semibold text-gray-500">
                    {{ $t('documentExtraction.archiveModal.content', total) }}
                </h5>
            </div>
        </template>
        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button variant="primary" @click="onArchive">
                    {{ $t('general.archive') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'success-archived'])

const props = defineProps({
    ids: {
        type: Array,
        default: null,
    },
})

const total = computed(() => (props.ids ? props.ids.length : 0))

const { execute, loading } = useApi('/api/document-extraction-archive', 'POST')

const closeModal = () => {
    emit('modal-close')
}

const onArchive = () =>
    execute({
        data: {
            ids: props.ids,
        },
    }).then(() => {
        emit('success-archived')
    })
</script>
