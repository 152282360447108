<template>
    <form-control-group>
        <form-control-group-row>
            <form-date-picker
                single
                auto-apply
                v-model="documentDate"
                :error-message="errorDateOfDocument"
                :highlight="documentDateHighlight"
                :label="$t('documentExtraction.edit.documentDate.label')"
            />
        </form-control-group-row>

        <form-control-group-row v-if="type === CONVERT_TYPE.BILL">
            <form-date-picker
                single
                auto-apply
                class="flex-1"
                v-model="dueDate"
                :error-message="errorDueDate"
                :highlight="dueDateHighlight"
                :label="$t('documentExtraction.edit.dueDate.label')"
            />
        </form-control-group-row>

        <form-control-group-row>
            <form-contact-select
                class="flex-1"
                v-model="contactId"
                :error-message="errorContactId || errorSupplier"
                :highlight="supplierNameHighlight"
                :label="$t('documentExtraction.edit.supplier.label')"
                :placeholder="
                    $t('documentExtraction.edit.supplier.placeholder')
                "
                :input-display="getContactName(contact)"
                :queries="contactQueries"
                @on-contact-changed="onContactChange"
            />
        </form-control-group-row>

        <form-control-group-row>
            <form-text-input
                v-model="documentNumber"
                :error-message="errorDocumentNumber"
                :highlight="documentNumberHighlight"
                :label="
                    $t('documentExtraction.edit.documentNumber.label', {
                        required: type === CONVERT_TYPE.BILL,
                    })
                "
                :placeholder="
                    $t('documentExtraction.edit.documentNumber.placeholder')
                "
            />
        </form-control-group-row>

        <form-control-group-row>
            <form-single-select
                v-model="currencyId"
                :error-message="errorCurrencyId"
                :label="$t('general.currency')"
                :options="currencies"
                option-value="value"
                option-label="iso"
                :searchable-fields="['iso', 'label']"
                @change="onCurrencyChange"
            />
        </form-control-group-row>

        <form-control-group-row>
            <form-single-select
                class="flex-1"
                v-model="type"
                :options="types"
                :label="$t('documentExtraction.edit.type.label')"
                :placeholder="$t('documentExtraction.edit.type.placeholder')"
                :error-message="errorType"
            />
        </form-control-group-row>

        <template v-if="type === CONVERT_TYPE.EXPENSE">
            <form-control-group-row>
                <form-single-select
                    v-model="paymentAccount"
                    :error-message="paymentAccountError"
                    :label="$t('documentExtraction.edit.paymentAccount.label')"
                    :placeholder="
                        $t('documentExtraction.edit.paymentAccount.placeholder')
                    "
                    :options="paymentAccounts"
                    option-value="id"
                    option-label="name"
                />
            </form-control-group-row>
        </template>

        <div v-if="matchItem" class="flex flex-col gap-2.5">
            <base-alert variant="gray">
                <span class="text-sm font-normal">
                    {{ $t('documentExtraction.match.description') }}
                </span>
            </base-alert>

            <div class="rounded-md bg-gray-50 p-3.5 text-sm">
                <p class="mb-1 font-semibold text-primary-500">
                    {{ $t('documentExtraction.match.bankMatch') }}
                </p>
                <div class="flex justify-between font-semibold text-gray-700">
                    <span>
                        {{
                            $filters.dateHumanFormat(matchItem.date_of_transfer)
                        }}
                    </span>
                    <span>
                        {{
                            $filters.symbolCurrency(
                                Math.abs(matchItem.amount),
                                matchItem.currency
                            )
                        }}
                    </span>
                </div>
                <template v-if="matchItem.payment_account">
                    <router-link
                        class="mb-2.5 block break-all font-normal text-gray-700 hover:text-primary-500"
                        :to="{
                            name: 'bookeeping.bank.detail',
                            params: { id: matchItem.payment_account.id },
                        }"
                    >
                        {{ matchItem.vendor?.company_name || matchItem.name }}
                    </router-link>
                    <p class="font-semibold text-gray-700">
                        {{ $t('documentExtraction.match.bankMatch') }}
                    </p>
                    <router-link
                        class="mb-2.5 block break-all font-normal text-gray-700 hover:text-primary-500"
                        :to="{
                            name: 'bookeeping.bank.detail',
                            params: { id: matchItem.payment_account.id },
                        }"
                    >
                        {{ matchItem.payment_account?.name }}
                    </router-link>
                </template>
                <base-button
                    variant="default"
                    size="sm"
                    v-if="autoFill"
                    @click="setAutoFill(false)"
                >
                    {{ $t('documentExtraction.match.undoAutofill') }}
                </base-button>
                <base-button
                    variant="primary"
                    size="sm"
                    v-else
                    @click="setAutoFill(true)"
                >
                    {{ $t('documentExtraction.match.autoFillPayment') }}
                </base-button>
            </div>
        </div>
    </form-control-group>
</template>

<script setup>
const props = defineProps({
    result: {
        type: Object,
        default: null,
    },
})

import { getContactName } from '@tenant/utils/helper'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'
import { dayjsFormat, now, shortServerFormat } from '@tenant/utils/day'
import { CONVERT_TYPE } from '@tenant/modules/tenant/document-extraction/utils/constants'

const { t } = useI18n()
const { defaultCurrency, currencies } = useCurrencies()
const { paymentAccounts } = usePaymentAccounts()

const matchItem = computed(() => {
    const { result } = props
    if (!result) {
        return null
    }

    return result.item?.match_item || result.match_item
})

const { value: documentNumberHighlight } = useHighlight('document_number')
const { value: documentDateHighlight } = useHighlight('date_of_document')
const { value: supplierNameHighlight } = useHighlight('supplier_name')
const { value: dueDateHighlight } = useHighlight('due_date')

const { value: documentNumber, errorMessage: errorDocumentNumber } =
    useField('document_number')
const { value: type, errorMessage: errorType } = useField('type')
const { value: documentDate, errorMessage: errorDateOfDocument } =
    useField('date_of_document')
const { value: supplier, errorMessage: errorSupplier } =
    useField('supplier_name')
const { value: contact } = useField('contact')
const { value: contactId, errorMessage: errorContactId } =
    useField('contact_id')
const { value: dueDate, errorMessage: errorDueDate } = useField('due_date')
const { value: autoFill, setValue: setAutoFill } = useField('auto_fill')
const { setValue: setMatchingTransactionId } = useField(
    'matching_transaction_id'
)
const {
    value: currencyId,
    errorMessage: errorCurrencyId,
    setValue: setCurrencyId,
} = useField('currency_id')
const { setValue: setCurrency } = useField('currency')
const {
    value: paymentAccount,
    errorMessage: paymentAccountError,
    setValue: setPaymentAccount,
} = useField('payment_account_id')
const { setValue: setExchangeRate } = useField('exchange_rate')

onMounted(() => {
    // Set document date as today if there's no value
    if (!documentDate.value) {
        documentDate.value = dayjsFormat(now(), DATE_SERVER_FORMAT)
    }
})

watch(
    () => autoFill.value,
    (value) => {
        if (!matchItem.value) {
            return
        }

        setPaymentAccount(value ? matchItem.value.payment_account?.id : null)
        setMatchingTransactionId(value ? matchItem.value.id : null)
    }
)

watchEffect(() => {
    if (currencyId.value) {
        const currencyObj = currencies.value.find(
            (currency) => currency.value === currencyId.value
        )
        setCurrency(currencyObj)
        return
    }

    if (!defaultCurrency.value) {
        return
    }

    setCurrencyId(defaultCurrency.value?.value)
    setCurrency(defaultCurrency.value)
})

const types = [
    {
        value: CONVERT_TYPE.BILL,
        label: t('documentExtraction.convertTypes.bill'),
    },
    {
        value: CONVERT_TYPE.EXPENSE,
        label: t('documentExtraction.convertTypes.expense'),
    },
]

const onContactChange = ({ item, id }) => {
    if (!item) {
        return
    }
    contact.value = item
    contactId.value = id
    supplier.value = getContactName(item)
}

const contactQueries = ref({})

watch(
    () => contactId.value,
    (value) => {
        if (!value) {
            contactQueries.value = {}
        } else {
            contactQueries.value = {
                'filter[id]': value,
            }
        }
    }
)

watch(
    () => contact.value,
    (value) => {
        if (!value) {
            return
        }

        supplier.value = getContactName(value)
    }
)

const onCurrencyChange = (_, currency) => {
    if (!currency) {
        return
    }

    setCurrency(currency)
    setExchangeRate({
        date: shortServerFormat(currency.rate_updated_at),
        rate: currency.rate,
    })
}
</script>
