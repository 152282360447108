<template>
    <data-grid
        table-classes="table-fixed"
        :table-borderless="true"
        :border-table-header="true"
        :columns="columns"
        :data-source="gridItems"
        row-classes="!bg-transparent"
        has-expandable-rows
    >
        <template #row-extra-content-container="{ item }">
            <data-grid-cell class="pb-2.5 pl-3 pr-0 pt-2.5" colspan="3">
                <div class="relative h-72 w-full">
                    <line-chart
                        ref="line"
                        height="100%"
                        :chartData="getChartData(item)"
                        :chartOptions="chartOptions"
                        :plugins="[corsair]"
                    />
                </div>
            </data-grid-cell>
        </template>

        <template #column-description="{ item }">
            <base-icon
                name="line-icons:arrows:chevron-down"
                variant="inherit"
                size="sm"
            />

            {{ item.item.description }}
        </template>

        <template #column-quantity="{ item }">
            {{ getQuantity(item) }}
        </template>

        <template #column-unit_price="{ item }">
            {{ $filters.symbolCurrency(item.item.price, item.item.currency) }}
        </template>
    </data-grid>
</template>

<script setup>
import { getShortNumber, numberFormat } from '@tenant/utils/helper'
import { corsair } from '@tenant/components/chart/plugins/corsair'
import { externalTooltipHandler } from '@tenant/components/chart/plugins/tooltip'

const props = defineProps({
    result: {
        type: Object,
        required: true,
    },
    multiLine: {
        type: Boolean,
        default: true,
    },
    oldLineItems: {
        type: Array,
        default: null,
    },
})

const { t } = useI18n()

const columns = [
    {
        property: 'description',
        label: t('general.name'),
        headerCellClass: 'w-9/12',
    },
    {
        property: 'quantity',
        label: t('general.quantity'),
        headerCellClass: 'w-24',
    },
    {
        property: 'unit_price',
        label: t('general.unitPrice'),
        headerCellClass: 'w-32 whitespace-nowrap',
    },
]

const lineItems = useFieldValue('line_items')

const tooltipHandler = (context) => {
    const { tooltip, chart } = context
    const { labels, datasets } = chart.data
    const dataIndex = tooltip.dataPoints[0].dataIndex

    const amount = datasets[0]['data'][dataIndex]
    const label = labels[dataIndex]

    const innerHTML = `
            <thead></thead>
            <tbody>
                <tr class="flex justify-between items-start w-full text-xs text-gray-700">
                    <td class="font-medium">${label.join(' ')}</td>
                    <td class="font-semibold">${numberFormat(amount)}</td>
                </tr>
            </tbody>
        `

    externalTooltipHandler(context, innerHTML)
}

const chartOptions = computed(() => ({
    maintainAspectRatio: false,
    interaction: {
        intersect: false,
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            position: 'nearest',
            external: tooltipHandler,
        },
        corsair: {
            vertical: false,
        },
    },
    responsive: true,
    scales: {
        x: {
            border: {
                display: false,
                dash: [6, 4],
            },
            ticks: {
                color: '#667085',
            },
            grid: {
                drawBorder: false,
                color: '#EAECF0',
            },
        },
        y: {
            beginAtZero: true,
            border: {
                display: false,
            },
            max: ({ chart }) => {
                let max = 5

                chart.data.datasets.forEach((dataset) => {
                    const datasetMax = Math.max(...dataset.data)
                    if (datasetMax === 5) {
                        max = 6
                    } else {
                        max = datasetMax > max ? datasetMax : max
                    }
                })

                return Math.max(chart.options.scales.y.suggestedMax, max)
            },
            ticks: {
                color: '#667085',
                count: 6,
                callback: function (val) {
                    return getShortNumber(val)
                },
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
    },
}))

const getQuantity = (item) => {
    return lineItems.value?.filter(
        (i) => i.description === item.item.description
    ).length
}

const getChartData = (item) => {
    return {
        labels: item.labels.map((i) => [i.name, i.year]),
        datasets: [
            {
                pointStyle: 'circle',
                pointBackgroundColor: '#7F56D9',
                pointBorderWidth: 1,
                pointHitRadius: 1,
                pointHoverBorderWidth: 2,
                label: t('general.amount'),
                borderColor: '#7F56D9',
                data: item.amounts,
                tension: 0.4,
                fill: true,
                backgroundColor: (ctx) => {
                    const canvas = ctx.chart.ctx
                    const gradient = canvas.createLinearGradient(0, 0, 0, 300)

                    gradient.addColorStop(0, 'rgba(127, 86, 217, 0.15)')
                    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)')

                    return gradient
                },
            },
        ],
    }
}

const { execute, result: items } = useApi(
    '/api/document-extraction/{id}/line-items',
    'GET'
)

const gridItems = computed(() => items.value || [])

onMounted(() => {
    execute({
        params: {
            id: props.result.id,
        },
    })
})
</script>
