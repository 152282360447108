<template>
    <base-sticky-heading
        :title="$t('documentExtraction.list.extractionInProcess')"
        class="min-h-[82px] items-center"
    >
        <data-grid
            table-borderless
            :data-source="entities.data"
            :columns="columns"
            :loading="!mounted ? false : loading"
            identify-field="id"
        >
            <template #column-preview="{ item }">
                <base-icon
                    v-if="item.preview"
                    variant="inherit"
                    name="line-icons:images:image-01"
                    @click="showPreview(item.preview)"
                />
            </template>

            <template #column-user="{ item }">
                <div class="text-sm font-normal text-gray-500">
                    {{ item.uploaded_by.full_name }}
                </div>
            </template>

            <template #column-process="{ item }">
                <base-badge
                    v-if="item.status === 'queue'"
                    :label="$t('general.inQueue')"
                    variant="warning"
                />
                <base-badge
                    v-else-if="item.status === 'failed'"
                    :label="$t('general.failed')"
                    variant="danger"
                />
                <base-loading v-else size="sm" />
            </template>

            <template #footer>
                <grid-pagination
                    :total="entities.last_page"
                    :page="entities.current_page"
                />
            </template>
        </data-grid>
        <document-extraction-preview-file
            :preview-files="state.previewFile"
            @modal-close="removePreview"
        />
    </base-sticky-heading>
</template>

<script setup>
import { DOCUMENT_PROCESSING_STATUS } from '@tenant/modules/tenant/document-extraction/utils/constants'
import { ECHO_CHANNELS, ECHO_EVENTS } from '@tenant/utils/constants'

const { t } = useI18n()

useHead({
    title: t('documentExtraction.list.extractionInProcess'),
})

const { useApiSearch, entities } = useApiFactory(
    'processing-document-extraction'
)
const { loading, execute } = useApiSearch(
    {
        filter: {
            status: DOCUMENT_PROCESSING_STATUS.PROCESSING,
        },
    },
    false
)

onMounted(async () => {
    await execute()
})

useTenantListen(
    ECHO_CHANNELS.GLOBAL,
    ECHO_EVENTS.DOCUMENT_EXTRACTION_UPLOADED,
    async ({ status }) => {
        switch (status) {
            case DOCUMENT_PROCESSING_STATUS.PROCESSING:
            case DOCUMENT_PROCESSING_STATUS.REVIEW:
            case DOCUMENT_PROCESSING_STATUS.FAILURE:
            case DOCUMENT_PROCESSING_STATUS.FAILED:
                await execute()
                if (mounted.value) {
                    mounted.value = false
                }
                break
        }
    },
    {
        leaveOnUnmount: false,
    }
)

const mounted = ref(true)

const state = reactive({
    previewFile: null,
})

const columns = [
    {
        property: 'preview',
        label: t('documentExtraction.list.columns.preview'),
    },
    {
        property: 'id',
        label: t('documentExtraction.list.columns.id'),
    },
    {
        property: 'original_file_name',
        label: t('documentExtraction.list.columns.fileName'),
    },
    {
        property: 'user',
        label: t('documentExtraction.list.columns.user'),
    },
    {
        property: 'process',
        label: t('documentExtraction.list.columns.extractionProcess'),
        center: true,
        headerCellClass: 'w-44',
    },
]

const showPreview = (previewFile) => {
    state.previewFile = [previewFile]
}

const removePreview = () => {
    state.previewFile = null
}
</script>
