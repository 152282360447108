<template>
    <div class="flex gap-4">
        <div
            class="flex min-h-20 min-w-8 flex-col items-center justify-start gap-1 text-gray-500"
        >
            <template v-if="activity.event === DOCUMENT_ACTIVITY.TYPE_CREATED">
                <base-icon
                    v-if="source === DOCUMENT_SOURCE_TYPE.SOURCE_UPLOAD"
                    variant="inherit"
                    name="line-icons:users:user-03"
                />
                <base-icon
                    v-else-if="source === DOCUMENT_SOURCE_TYPE.SOURCE_EMAIl"
                    variant="inherit"
                    name="line-icons:communication:mail-01"
                />
                <base-icon
                    v-else-if="source === DOCUMENT_SOURCE_TYPE.SOURCE_CLOUD"
                    variant="inherit"
                    name="line-icons:general:upload-cloud-02"
                />
            </template>
            <template
                v-else-if="activity.event === DOCUMENT_ACTIVITY.TYPE_MOVE"
            >
                <base-avatar :user="causer" size="sm" />
            </template>
            <template v-else>
                <base-icon
                    variant="inherit"
                    name="line-icons:general:check-circle"
                />
            </template>

            <div class="h-full w-0.5 rounded-full bg-gray-200" />
        </div>

        <div class="flex flex-col overflow-hidden">
            <div class="flex items-center gap-2">
                <p class="min-h-5 truncate text-sm font-medium text-gray-700">
                    <template
                        v-if="activity.event === DOCUMENT_ACTIVITY.TYPE_CREATED"
                    >
                        <template
                            v-if="source === DOCUMENT_SOURCE_TYPE.SOURCE_UPLOAD"
                        >
                            {{
                                $t('documentExtraction.uploadedBy', {
                                    name: causer.full_name,
                                })
                            }}
                        </template>
                        <template
                            v-else-if="
                                source === DOCUMENT_SOURCE_TYPE.SOURCE_EMAIl
                            "
                        >
                            {{ $t('documentExtraction.importViaEmail') }}
                        </template>
                        <template
                            v-else-if="
                                source === DOCUMENT_SOURCE_TYPE.SOURCE_CLOUD
                            "
                        >
                            {{ $t('documentExtraction.importViaCloud') }}
                        </template>
                    </template>

                    <template
                        v-else-if="
                            activity.event === DOCUMENT_ACTIVITY.TYPE_MOVE
                        "
                    >
                        {{ causer.full_name }}
                    </template>

                    <template v-else>
                        {{ $t('general.appName') }}
                    </template>
                </p>

                <time
                    class="whitespace-nowrap text-xs font-normal text-gray-500"
                >
                    {{
                        $t('longTimeAgo', {
                            diffDate: activity.diff_date,
                        })
                    }}
                </time>
            </div>

            <span class="line-clamp-2 text-sm font-normal text-gray-500">
                <template
                    v-if="activity.event === DOCUMENT_ACTIVITY.TYPE_CREATED"
                >
                    <span
                        class="flex cursor-pointer items-center gap-1 font-medium text-primary-700"
                        @click="onDownload(fileDocument)"
                    >
                        {{ fileDocument.name }}

                        <base-icon
                            name="line-icons:general:link-external-02"
                            size="sm"
                            variant="inherit"
                        />
                    </span>
                </template>
                <template
                    v-if="
                        activity.event === DOCUMENT_ACTIVITY.TYPE_FIELD_IDENTIFY
                    "
                >
                    {{
                        $t(
                            'documentExtraction.fieldsWereIdentified',
                            properties.extracted_fields_count
                        )
                    }}
                </template>
                <template
                    v-else-if="activity.event === DOCUMENT_ACTIVITY.TYPE_MOVE"
                >
                    {{
                        $t('documentExtraction.movedTheDocumentTo', {
                            status: $t('documentExtractionStatus', {
                                status: properties.to,
                            }).toLowerCase(),
                        })
                    }}
                </template>
                <template
                    v-else-if="
                        activity.event === DOCUMENT_ACTIVITY.TYPE_FOUND_CONTACT
                    "
                >
                    {{
                        $t('documentExtraction.existingContactFound', {
                            name: properties.name,
                        })
                    }}
                </template>
                <template
                    v-else-if="
                        activity.event === DOCUMENT_ACTIVITY.TYPE_FOUND_VAT
                    "
                >
                    {{
                        $t('documentExtraction.vatWasDetected', {
                            properties: properties,
                        })
                    }}
                </template>
                <template
                    v-else-if="
                        activity.event === DOCUMENT_ACTIVITY.TYPE_FOUND_COA
                    "
                >
                    {{
                        $t('documentExtraction.coaWasDetected', {
                            properties: properties,
                        })
                    }}
                </template>
            </span>
        </div>
    </div>
</template>

<script setup>
import {
    DOCUMENT_ACTIVITY,
    DOCUMENT_SOURCE_TYPE,
} from '@tenant/modules/tenant/document-extraction/utils/constants'

const props = defineProps({
    documentExtraction: {
        type: Object,
        required: true,
    },
    activity: {
        type: Object,
        required: true,
    },
})

const emitter = useEmitter()
const { downloadFile } = useFileStorage()

const fileDocument = computed(
    () => props.documentExtraction.documents?.[0] || null
)

const properties = computed(() => props.activity.properties || [])

const causer = computed(() => props.activity.causer)

const source = computed(() => properties.value?.source)

const onDownload = async (item) => {
    if (!item.preview) {
        return
    }

    try {
        emitter.emit('set-loading', true)
        await downloadFile(item.preview.path, item.full_name)
    } finally {
        emitter.emit('set-loading', false)
    }
}
</script>
