<template>
    <div class="p-6">
        <div class="mb-6 flex items-center justify-between">
            <p class="text-md font-medium text-gray-700">
                {{ $t('documentExtraction.vendorHistory') }}
            </p>
            <base-button
                variant="default"
                size="sm"
                icon="line-icons:charts:bar-chart-01"
                @click="onToggleShowVariance"
            >
                {{ $t('documentExtraction.variance') }}
            </base-button>
        </div>

        <div v-if="chartData" class="relative h-64">
            <stackbar-chart
                height="100%"
                :chart-data="chartData"
                :chart-options="chartOptions"
                :custom-data-set="customDataSet"
                :plugins="[corsair]"
            />
        </div>
        <p class="text-center text-xs font-medium text-gray-500">
            {{ $t('general.month') }}
        </p>
    </div>

    <document-extraction-edit-variance-modal
        :show="state.showVariance"
        :result="result"
        @modal-close="onToggleShowVariance"
    />
</template>

<script setup>
import {
    getShortNumber,
    increaseBrightness,
    numberFormat,
} from '@tenant/utils/helper'
import { externalTooltipHandler } from '@tenant/components/chart/plugins/tooltip'
import { corsair } from '@tenant/components/chart/plugins/corsair'

const props = defineProps({
    result: {
        type: Object,
        required: true,
    },
    multiLine: {
        type: Boolean,
        default: true,
    },
    oldLineItems: {
        type: Array,
        default: null,
    },
})

const router = useRouter()

const state = reactive({
    showVariance: false,
})

const { execute, result: summaryData } = useApi(
    '/api/document-extraction/{id}/summary',
    'GET'
)

onMounted(() => {
    execute({
        params: {
            id: props.result.id,
        },
    })
})

const tooltipHandler = (context) => {
    const { tooltip, chart } = context
    const { datasets } = chart.data
    const dataIndex = tooltip.dataPoints[0].dataIndex
    const datasetIndex = tooltip.dataPoints[0].datasetIndex

    const item = datasets[datasetIndex]['data'][dataIndex]
    const label = summaryData.value.labels[dataIndex]

    const innerHTML = `
            <thead></thead>
            <tbody>
                <tr class="flex justify-between items-start w-full text-xs text-gray-700">
                    <td class="font-medium">
                      ${[label.name, label.year].join(' ')}
                    </td>
                    <td class="font-semibold">
                      ${numberFormat(item.amount)}
                    </td>
                </tr>
            </tbody>
        `

    externalTooltipHandler(context, innerHTML)
}

const chartData = computed(() => {
    if (!summaryData.value) {
        return null
    }

    let color = '#7F56D9'

    return {
        labels: summaryData.value.labels.map((i) => i.name),
        datasets: summaryData.value.stacks
            .map((stack) => {
                const dataSet = {
                    barThickness: 40,
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: color,
                    backgroundColor: color,
                    hoverBackgroundColor: '#B692F6',
                    data: stack,
                }

                color = increaseBrightness(color, 15)

                return dataSet
            })
            .reverse(),
    }
})

const chartOptions = computed(() => ({
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            position: 'nearest',
            external: tooltipHandler,
        },
        corsair: {
            horizontal: false,
        },
    },
    parsing: {
        xAxisKey: 'amount',
        yAxisKey: 'amount',
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
            ticks: {
                color: '#667085',
            },
            border: {
                display: false,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        y: {
            stacked: true,
            border: {
                display: false,
            },
            beginAtZero: true,
            max: ({ chart }) => {
                let max = 5

                const maxData = []
                chart.data.datasets.forEach((dataset) => {
                    dataset.data.forEach((data, idx) => {
                        if (maxData[idx]) {
                            maxData[idx] = data.amount + maxData[idx]
                        } else {
                            maxData[idx] = data.amount
                        }
                    })
                })

                const datasetMax = Math.max(...maxData)
                if (datasetMax === 5) {
                    max = 6
                } else {
                    max = datasetMax > max ? datasetMax : max
                }

                return Math.max(chart.options.scales.y.suggestedMax, max)
            },
            ticks: {
                color: '#667085',
                count: 6,
                padding: 8,
                callback: function (val) {
                    return getShortNumber(val)
                },
            },
            grid: {
                drawBorder: false,
                color: '#F2F4F7',
            },
        },
    },
    onClick: onClickChart,
}))

const customDataSet = (data) => data.map((i) => i.amount)

const onClickChart = (e) => {
    const { chart } = e
    const res = chart.getElementsAtEventForMode(
        e,
        'nearest',
        { intersect: true },
        true
    )

    const element = res?.[0]?.element
    if (!element) {
        return
    }

    const data = element?.['$context']?.raw
    if (!data) {
        return
    }

    router.push({
        name: 'purchases.bill.detail',
        params: {
            id: data.id,
        },
    })
}

const onToggleShowVariance = () => {
    state.showVariance = !state.showVariance
}
</script>
