<template>
    <div class="flex items-center gap-2.5">
        <div class="w-44">
            <form-date-picker
                v-model="fromDate"
                single
                size="md"
                :clearable="false"
                @update:model-value="onchangeFromDate"
                custom-display-format="D. MMM YYYY"
            >
                <template #icon="{ value }">
                    <base-icon
                        v-if="value"
                        name="line-icons:arrows:chevron-left"
                        variant="inherit"
                        @click="leftBack"
                    />
                </template>
            </form-date-picker>
        </div>

        <span class="text-sm font-medium lowercase">
            {{ $t('filters.to') }}
        </span>

        <div class="w-44">
            <form-date-picker
                size="md"
                v-model="toDate"
                single
                @update:model-value="onchangeToDate"
                :clearable="false"
                custom-display-format="D. MMM YYYY"
            >
                <template #icon="{ value }">
                    <div class="hidden" v-if="value" />
                </template>
                <template #right-icon>
                    <base-icon
                        name="line-icons:arrows:chevron-right"
                        variant="inherit"
                        @click="rightForward"
                    />
                </template>
            </form-date-picker>
        </div>
    </div>
</template>
<script setup>
import { shortServerFormat } from '@tenant/utils/day'

const emit = defineEmits(['updateRange'])

const _syncDateRange = (date, direction, addDates = 0) => {
    date = new Date(date)
    const mutationDate = new Date(date.setMonth(date.getMonth() + direction))

    mutationDate.setDate(mutationDate.getDate() + addDates)

    return shortServerFormat(mutationDate)
}

const leftBack = (e) => {
    e.stopPropagation()
    fromDate.value = _syncDateRange(fromDate.value, -2)
    toDate.value = _syncDateRange(toDate.value, -2)
}

const rightForward = (e) => {
    e.stopPropagation()
    fromDate.value = _syncDateRange(fromDate.value, 2)
    toDate.value = _syncDateRange(toDate.value, 2)
}

//watch from date and to date
const onchangeFromDate = () => {
    toDate.value = _syncDateRange(fromDate.value, 2, -1)
}

const onchangeToDate = () => {
    fromDate.value = _syncDateRange(toDate.value, -2, 1)
}

const currentDate = new Date()
const toDate = ref(shortServerFormat(currentDate))
const fromDate = ref(_syncDateRange(toDate.value, -2, 1))

watch(
    () => [fromDate.value, toDate.value],
    () => {
        emit('updateRange', {
            fromDate: fromDate.value,
            toDate: toDate.value,
        })
    },
    {
        immediate: true,
    }
)
</script>
