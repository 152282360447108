export const UPLOAD_TYPE = {
    PER_FILE: 'per_file',
    PAGE: 'per_page',
}

export const CONVERT_TYPE = {
    BILL: 'bill',
    EXPENSE: 'expense',
}

export const DOCUMENT_STATUS = {
    NEW: 'new',
    VIEWED: 'viewed',
    READY: 'ready',
    FAILED: 'failed',
}

export const DOCUMENT_PROCESSING_STATUS = {
    PROCESSING: 'processing',
    FAILURE: 'failure',
    FAILED: 'failed',
    REVIEW: 'review',
}

export const DOCUMENT_ACTIVITY = {
    TYPE_CREATED: 'created',
    TYPE_FIELD_IDENTIFY: 'field_identify',
    TYPE_FOUND_CONTACT: 'found_contact',
    TYPE_FOUND_VAT: 'found_vat',
    TYPE_FOUND_COA: 'found_coa',
    TYPE_MOVE: 'move',
}

export const DOCUMENT_SOURCE_TYPE = {
    SOURCE_EMAIl: 'email',
    SOURCE_CLOUD: 'cloud',
    SOURCE_UPLOAD: 'upload',
}
