<template>
    <document-extraction-edit-view
        :parent-route="parentRouteName"
        :grand-route="grandRouteName"
    >
        <template
            #right="{
                status,
                type,
                onSave,
                onMoveToReady,
                onConvertTo,
                hasErrors,
            }"
        >
            <base-button
                variant="primary"
                size="sm"
                :disabled="hasErrors"
                @click="onSave"
            >
                {{ $t('general.save') }}
            </base-button>

            <template v-if="status !== DOCUMENT_STATUS.READY">
                <base-button
                    variant="primary"
                    size="sm"
                    icon="line-icons:general:check-circle"
                    :disabled="hasErrors"
                    @click="onMoveToReady"
                >
                    {{ $t('documentExtraction.list.button.moveToReady') }}
                </base-button>
            </template>

            <base-button
                v-else-if="type"
                variant="primary"
                icon="line-icons:general:save-01"
                size="sm"
                :disabled="hasErrors"
                @click="onConvertTo"
            >
                {{
                    $t('documentExtraction.publishAs', {
                        name: t(`documentExtraction.convertTypes.${type}`),
                    })
                }}
            </base-button>
        </template>
    </document-extraction-edit-view>
</template>

<script setup>
import { DOCUMENT_STATUS } from '@tenant/modules/tenant/document-extraction/utils/constants'

const { t } = useI18n()
useHead({ title: t('documentExtraction.edit.title') })

const route = useRoute()

const parentRouteName = computed(() => {
    const matchedRoute = route.matched[route.matched.length - 2]

    return matchedRoute?.name
})

const grandRouteName = computed(() => {
    const matchedRoute = route.matched[route.matched.length - 3]

    return matchedRoute?.name
})
</script>
