<template>
    <base-sticky-heading
        :title="$t('documentExtraction.list.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <document-extraction-heading />
        </template>
        <document-extraction-grid
            ref="gridRef"
            @on-items-selected="onItemSelected"
        >
            <template #grow>
                <div class="flex gap-2.5">
                    <base-button
                        size="sm"
                        variant="default"
                        :disabled="state.selectedItems.length === 0"
                        @click="onArchive"
                        v-if="$acl.can('update_document_extractions')"
                    >
                        {{ $t('general.archive') }}
                    </base-button>
                </div>
            </template>
        </document-extraction-grid>
    </base-sticky-heading>

    <document-extraction-archive-modal
        :ids="state.archivedIds"
        @modal-close="onCloseArchiveModal"
        @success-archived="onSuccessArchived"
    />
</template>

<script setup>
const { t } = useI18n()
const gridRef = ref(null)

const state = reactive({
    selectedItems: [],
    archivedIds: null,
})

useHead({
    title: t('documentExtraction.list.title'),
})

const onItemSelected = (items) => {
    state.selectedItems = items
}

const onArchive = () => {
    state.archivedIds = state.selectedItems.map((i) => i.id)
}

const onCloseArchiveModal = () => {
    state.archivedIds = null
}

const onSuccessArchived = () => {
    onCloseArchiveModal()
    gridRef.value.executeRefreshSearch()
}
</script>
