<template>
    <div class="flex flex-col gap-1 p-6">
        <document-extraction-edit-view-protocol-item
            v-for="activity in activities"
            :key="activity.id"
            :activity="activity"
            :document-extraction="result"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    result: {
        type: Object,
        required: true,
    },
    multiLine: {
        type: Boolean,
        default: true,
    },
    oldLineItems: {
        type: Array,
        default: null,
    },
})

const { execute, result: activities } = useApi(
    '/api/document-extraction/{id}/activities',
    'GET'
)

onMounted(() => {
    execute({
        params: {
            id: props.result.id,
        },
    })
})
</script>
