<template>
    <div class="flex flex-col gap-6 text-xl" v-if="iso">
        <div class="flex">
            <div class="flex-1 text-left">
                {{ $t('documentExtraction.edit.summary.net') }}
            </div>
            <div class="flex-1 text-right">
                {{ $filters.currency(amountNet, iso, 2) }}
            </div>
        </div>

        <div class="flex" v-for="(value, key) in vatAmounts" :key="key">
            <div class="flex-1 text-left">
                {{ $t('documentExtraction.edit.summary.tax') }}
                <span v-if="value.taxRule">{{ value.taxRule?.tax_rate }}</span>
            </div>
            <div class="flex-1 text-right">
                {{ $filters.currency(value.vat, iso, 2) }}
            </div>
        </div>

        <div class="flex font-semibold">
            <span class="flex-1 text-left text-2xl">{{
                $t('documentExtraction.edit.summary.total')
            }}</span>
            <span class="flex-1 text-right">
                {{ $filters.currency(amountGross, iso, 2) }}
            </span>
        </div>
    </div>
</template>

<script setup>
import { useExchangeRate } from '@tenant/composables/use-exchange-rate'

const { fields } = useFieldArray('line_items')
const taxType = useFieldValue('tax_type')

const { totalGross, totalNet, vatAmounts } = useNetGross(fields, taxType)

const { value: amountNet } = useField('amount_net_total')
const { value: amountGross } = useField('amount_gross_total')

// Update `amount_net_total` and `amount_gross_total` fields based on computed values from `useInvoice`
watch(totalGross, () => (amountGross.value = totalGross.value), {
    immediate: true,
})
watch(totalNet, () => (amountNet.value = totalNet.value), {
    immediate: true,
})

const currency = useFieldValue('currency')
const { iso } = useExchangeRate(currency)
</script>
