<template>
    <base-button
        variant="default"
        icon="line-icons:general:filter-lines"
        @click="toggleFilterMenu(true)"
    >
        <span class="whitespace-nowrap">
            <span>{{ $t('general.filters') }}</span>
            <span v-if="filterCount"> ({{ filterCount }})</span>
        </span>
    </base-button>

    <slide-out-menu-filters
        :show="showFilterMenu"
        @close="toggleFilterMenu(false)"
        @reset="onResetFilters"
    >
        <base-section vertical hide-info-block>
            <template #right>
                <form-control-group>
                    <form-control-group-row>
                        <form-filter-date-range
                            :label="
                                $t(
                                    'documentExtraction.list.filters.dateOfDocument'
                                )
                            "
                            query-key="date_of_document"
                            :query-value="queries.date_of_document"
                        />
                    </form-control-group-row>

                    <hr class="my-2" />

                    <form-control-group-row>
                        <form-single-select
                            v-model="document"
                            :label="
                                $t('documentExtraction.list.filters.document')
                            "
                            :options="documents"
                        />
                    </form-control-group-row>

                    <hr class="my-2" />

                    <label class="text-sm font-medium text-gray-700">
                        {{ $t('documentExtraction.list.filters.amountNet') }}
                    </label>
                    <form-control-group-row>
                        <form-filter-range
                            query-key="amount_net_total"
                            :query-value="queries.amount_net_total"
                        />
                    </form-control-group-row>

                    <hr class="my-2" />
                    <form-control-group-row>
                        <div class="flex w-full flex-col gap-3">
                            <label class="text-sm font-medium text-gray-700">
                                {{ $t('documentExtraction.list.filters.flag') }}
                            </label>
                            <base-button-group class="w-full">
                                <base-button
                                    variant="default"
                                    class="flex-1 justify-center"
                                    :class="{
                                        'bg-gray-100': filterValues.flag === 1,
                                    }"
                                    @click="flag = 1"
                                >
                                    {{
                                        $t(
                                            'documentExtraction.list.filters.flagged'
                                        )
                                    }}
                                </base-button>
                                <base-button
                                    variant="default"
                                    class="flex-1 justify-center"
                                    :class="{
                                        'bg-gray-100': filterValues.flag === 0,
                                    }"
                                    @click="flag = 0"
                                >
                                    {{
                                        $t(
                                            'documentExtraction.list.filters.unFlagged'
                                        )
                                    }}
                                </base-button>
                            </base-button-group>
                        </div>
                    </form-control-group-row>
                </form-control-group>
            </template>
        </base-section>
    </slide-out-menu-filters>
</template>

<script setup>
import { isNil } from 'lodash-es'

const { t } = useI18n()

const queries = {
    date_of_document: 'filter.date_of_document',
    documents: 'filter.documents',
    amount_net_total: 'filter.amount_net_total',
    flag: 'filter.flag',
}

const documents = [
    {
        value: 1,
        label: t('general.available'),
    },
    {
        value: 0,
        label: t('general.unavailable'),
    },
]

const document = computed({
    get: () => {
        const { documents } = filterValues.value
        return (documents && documents[1]) || null
    },
    set: (value) => {
        let updated = null
        if (!isNil(value) && value !== '') {
            updated = ['HAS', value]
        }

        updateFilters({
            [queries.documents]: updated,
        })
    },
})

const flag = computed({
    get: () => filterValues.value.flag,
    set: (value) => {
        let updated = value
        if (value === flag.value) {
            updated = null
        }

        updateFilters({
            [queries.flag]: updated,
        })
    },
})

const { filterValues, filterCount, updateFilters, resetFilters } =
    useFilters(queries)

const showFilterMenu = ref(false)
const toggleFilterMenu = (value) => (showFilterMenu.value = value)

const onResetFilters = () => {
    resetFilters().then(() => {
        showFilterMenu.value = false
    })
}
</script>
