import { object, string } from 'yup'
import { CONVERT_TYPE } from '@tenant/modules/tenant/document-extraction/utils/constants'
import { isNil } from 'lodash-es'

export const useGetDocumentExtractions = (queries = {}) => {
    const { useApiSearch, entities } = useApiFactory('document-extraction')

    const { loading, execute, executeRefreshSearch } = useApiSearch({
        ...queries,
    })

    return {
        entities,
        loading,
        execute,
        executeRefreshSearch,
    }
}

export const useValidateDocument = () => {
    const { t } = useI18n()

    const generalValidateSchema = () => {
        return object({
            document_number: string()
                .when('type', {
                    is: CONVERT_TYPE.BILL,
                    then: (schema) =>
                        schema.required(
                            t('validation.required', {
                                field: t(
                                    'documentExtraction.edit.documentNumber.label'
                                ),
                            })
                        ),
                    otherwise: (schema) => schema.nullable(),
                })
                .transform((o, c) => (isNil(o) || o === '' ? null : c))
                .min(
                    3,
                    t('validation.min', {
                        min: 3,
                        field: t(
                            'documentExtraction.edit.documentNumber.label'
                        ),
                    })
                ),
            date_of_document: string().required(
                t('validation.required', {
                    field: t('documentExtraction.edit.documentDate.label'),
                })
            ),
            due_date: string()
                .nullable()
                .when('type', {
                    is: CONVERT_TYPE.BILL,
                    then: (schema) =>
                        schema.required(
                            t('validation.required', {
                                field: t(
                                    'documentExtraction.edit.dueDate.label'
                                ),
                            })
                        ),
                    otherwise: (schema) => schema,
                }),
            contact_id: string().required(
                t('validation.required', {
                    field: t('documentExtraction.edit.supplier.label'),
                })
            ),
            type: string(),
            payment_account_id: string()
                .nullable()
                .when('type', {
                    is: CONVERT_TYPE.EXPENSE,
                    then: (schema) =>
                        schema.required(
                            t('validation.required', {
                                field: t(
                                    'documentExtraction.edit.paymentAccount.label'
                                ),
                            })
                        ),
                    otherwise: (schema) => schema,
                }),
        })
    }

    const lineItemsValidateSchema = () => {
        return object({
            chart_of_account_id: string().required(
                t('validation.required', {
                    field: t(
                        'documentExtraction.edit.lineItem.chartOfAccount.label'
                    ),
                })
            ),
            amount: string().required(
                t('validation.required', {
                    field: t('documentExtraction.edit.lineItem.amount.label'),
                })
            ),
            tax_rule_id: string().required(
                t('validation.required', {
                    field: t('documentExtraction.edit.lineItem.tax.label'),
                })
            ),
        })
    }

    const getMappingErrors = (item) => {
        const errors = []

        try {
            generalValidateSchema().validateSync(item, {
                abortEarly: false,
            })
        } catch (e) {
            const unique = [
                ...new Map(e.inner.reverse().map((i) => [i.path, i])).values(),
            ]

            errors.push(...unique.reverse().map((i) => i.message))
        }

        for (const lineItem of item.line_items) {
            try {
                lineItemsValidateSchema().validateSync(lineItem)
            } catch (e) {
                errors.push(t('validation.lineItems'))
                break
            }
        }

        return errors
    }

    return { generalValidateSchema, lineItemsValidateSchema, getMappingErrors }
}
