<template>
    <div class="flex flex-1 flex-col gap-6 p-6">
        <template v-if="currency">
            <div class="flex flex-col">
                <h5 class="mb-2.5 text-lg font-medium text-gray-900">
                    {{ $t('general.summary') }}
                </h5>
                <div class="flex items-center gap-2.5 text-sm">
                    <span class="font-normal text-gray-500">
                        {{
                            $t('documentExtraction.totalSpend', {
                                amount: totalSpend.amount,
                            })
                        }}
                    </span>
                    <span
                        class="flex gap-2.5 font-medium"
                        :class="getTextClass(totalSpend.amount < 0)"
                    >
                        {{
                            $filters.symbolCurrency(
                                Math.abs(totalSpend.amount),
                                currency
                            )
                        }}
                        <span
                            v-if="isNil(totalSpend.percent)"
                            class="text-gray-500"
                        >
                            {{ $t('general.na') }}
                        </span>

                        <span v-else class="flex items-center gap-0.5">
                            {{ $filters.numberFormat(totalSpend.percent) }}%

                            <base-icon
                                :name="getIconName(totalSpend.amount < 0)"
                                variant="inherit"
                            />
                        </span>
                    </span>
                </div>
            </div>

            <div class="flex flex-col">
                <h5 class="mb-2.5 text-sm font-medium text-gray-900">
                    {{ $t('documentExtraction.lineItemBreakdown') }}
                </h5>
                <div class="flex flex-col gap-2.5">
                    <div
                        class="flex items-center gap-1 text-sm"
                        v-if="increased.total > 0"
                    >
                        <span class="font-normal text-gray-500">
                            {{
                                $t('documentExtraction.lineItemsFor', {
                                    amount: increased.amount,
                                    count: increased.total,
                                })
                            }}
                        </span>
                        <span
                            class="flex items-center gap-0.5 font-medium"
                            :class="getTextClass(increased.amount < 0)"
                        >
                            {{
                                $filters.symbolCurrency(
                                    Math.abs(increased.amount),
                                    currency
                                )
                            }}
                            <base-icon
                                :name="getIconName(increased.amount < 0)"
                                variant="inherit"
                            />
                        </span>
                        <span class="font-normal text-gray-500">
                            {{ $t('documentExtraction.inTotal') }}
                        </span>
                    </div>

                    <div
                        class="flex items-center gap-1 text-sm"
                        v-if="decreased.total > 0"
                    >
                        <span class="font-normal text-gray-500">
                            {{
                                $t('documentExtraction.lineItemsFor', {
                                    amount: decreased.amount,
                                    count: decreased.total,
                                })
                            }}
                        </span>
                        <span
                            class="flex items-center gap-0.5 font-medium"
                            :class="getTextClass(decreased.amount < 0)"
                        >
                            {{
                                $filters.symbolCurrency(
                                    Math.abs(decreased.amount),
                                    currency
                                )
                            }}
                            <base-icon
                                :name="getIconName(decreased.amount < 0)"
                                variant="inherit"
                            />
                        </span>
                        <span class="font-normal text-gray-500">
                            {{ $t('documentExtraction.inTotal') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col">
                <h5 class="mb-2.5 text-sm font-medium text-gray-900">
                    {{ $t('documentExtraction.largestDriver') }}
                </h5>
                <div class="flex flex-col gap-2.5">
                    <div class="flex items-start gap-1 text-sm">
                        <span
                            class="flex items-center gap-0.5 font-medium"
                            :class="getTextClass(largestDriver.amount < 0)"
                        >
                            {{
                                $filters.symbolCurrency(
                                    Math.abs(largestDriver.amount),
                                    currency
                                )
                            }}
                            <base-icon
                                :name="getIconName(largestDriver.amount < 0)"
                                variant="inherit"
                            />
                        </span>

                        <span class="font-normal text-gray-500">
                            {{
                                $t('documentExtraction.differenceIn', {
                                    amount: largestDriver.amount,
                                    name: largestDriver.description,
                                })
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
import { isNil } from 'lodash'
import {
    getIconName,
    getTextClass,
} from '@tenant/modules/tenant/document-extraction/utils/helper'

const props = defineProps({
    summaryData: {
        type: Object,
        required: true,
    },
})

const currency = computed(() => props.summaryData.currency)
const totalSpend = computed(() => props.summaryData.total_spend)
const increased = computed(() => props.summaryData.increased)
const decreased = computed(() => props.summaryData.decreased)
const largestDriver = computed(() => props.summaryData.largest_driver)
</script>
