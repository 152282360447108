<template>
    <data-grid
        :columns="columns"
        :data-source="varianceData"
        :table-borderless="true"
        :border-table-header="true"
    >
        <template #header-start_amount>
            {{ startDate }}
        </template>
        <template #header-end_amount>
            {{ endDate }}
        </template>

        <template #column-name="{ item }">
            {{ item.item.description }}
        </template>

        <template #column-start_amount="{ item }">
            {{ $filters.symbolCurrency(item.start_amount, item.item.currency) }}
        </template>

        <template #column-end_amount="{ item }">
            {{ $filters.symbolCurrency(item.end_amount, item.item.currency) }}
        </template>

        <template #column-difference_amount="{ item }">
            <span
                class="font-medium"
                :class="getTextClass(item.difference.amount < 0)"
            >
                <template v-if="item.difference.amount < 0">
                    {{
                        $filters.numberFormat(Math.abs(item.difference.amount))
                    }}
                </template>
                <template v-else>
                    ({{
                        $filters.numberFormat(Math.abs(item.difference.amount))
                    }})
                </template>
            </span>
        </template>

        <template #column-difference_percent="{ item }">
            <span class="font-medium" v-if="isNil(item.difference.percent)">
                {{ $t('general.na') }}
            </span>
            <template v-else>
                <span
                    class="font-medium"
                    :class="getTextClass(item.difference.percent < 0)"
                >
                    {{
                        $filters.numberFormat(
                            Math.abs(item.difference.percent)
                        )
                    }}%
                </span>
            </template>
        </template>

        <template #column-trend="{ item }">
            <div class="relative h-10">
                <line-chart
                    class="w-full"
                    :chart-data="lineChartData(item)"
                    :chart-options="lineChartOptions"
                    height="100%"
                />
            </div>
        </template>
    </data-grid>
</template>

<script setup>
import { isNil } from 'lodash'
import { getTextClass } from '@tenant/modules/tenant/document-extraction/utils/helper'

defineProps({
    startDate: {
        type: String,
        default: null,
    },
    endDate: {
        type: String,
        default: null,
    },
    varianceData: {
        type: Array,
        required: true,
    },
})

const { t } = useI18n()

const columns = computed(() => [
    {
        property: 'name',
        label: t('documentExtraction.varianceGrid.name'),
        suppressRowClick: true,
    },
    {
        property: 'start_amount',
        label: '',
        headerCellClass: 'pl-1 pr-1 w-24',
        dataCellClass: 'pl-1 pr-1',
        suppressRowClick: true,
    },
    {
        property: 'end_amount',
        label: '',
        headerCellClass: 'pl-1 pr-1 w-24',
        dataCellClass: 'pl-1 pr-1',
        suppressRowClick: true,
    },
    {
        property: 'difference_amount',
        label: t('documentExtraction.varianceGrid.difference'),
        headerCellClass: 'pl-1 pr-1 w-24',
        dataCellClass: 'pl-1 pr-1',
        suppressRowClick: true,
    },
    {
        property: 'difference_percent',
        label: '%',
        headerCellClass: 'pl-1 pr-1 w-24',
        dataCellClass: 'pl-1 pr-1',
        suppressRowClick: true,
    },
    {
        property: 'trend',
        label: t('documentExtraction.varianceGrid.trend'),
        headerCellClass: 'pl-1 pr-1 w-36',
        dataCellClass: 'pl-1 pr-1 pt-1 pb-1',
        suppressRowClick: true,
    },
])

const lineChartOptions = computed(() => ({
    maintainAspectRatio: false,
    layout: {
        padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
        },
    },
    responsive: true,
    scales: {
        x: {
            border: {
                display: false,
            },
            ticks: {
                display: false,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        y: {
            beginAtZero: true,
            border: {
                display: false,
            },
            ticks: {
                display: false,
                color: '#667085',
                count: 6,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
    },
}))

const lineChartData = (item) => {
    return {
        labels: item.trend.labels.map((i) => [i.day, i.month]),
        datasets: [
            {
                pointStyle: false,
                data: item.trend.amounts,
                borderColor: item.difference.amount < 0 ? '#039855' : '#D92D20',
                borderWidth: 1,
            },
        ],
    }
}
</script>
