<template>
    <div class="flex-1 border-r border-gray-300 p-6">
        <div class="relative h-64">
            <stackbar-chart
                v-if="barChartData"
                height="100%"
                :chart-data="barChartData"
                :chart-options="barChartOptions"
                :plugins="[corsair, customPlugins]"
            />
        </div>
    </div>
</template>

<script setup>
import { corsair } from '@tenant/components/chart/plugins/corsair'
import { getShortNumber, numberFormat } from '@tenant/utils/helper'
import { externalTooltipHandler } from '@tenant/components/chart/plugins/tooltip'

const props = defineProps({
    startDate: {
        type: String,
        default: null,
    },
    endDate: {
        type: String,
        default: null,
    },
    varianceData: {
        type: Array,
        required: true,
    },
})

const tooltipHandler = (context) => {
    const { tooltip, chart } = context
    const { datasets } = chart.data
    const dataIndex = tooltip.dataPoints[0].dataIndex
    const datasetIndex = tooltip.dataPoints[0].datasetIndex

    const amount = datasets[datasetIndex]['data'][dataIndex]
    const label = dataIndex === 0 ? props.startDate : props.endDate

    const innerHTML = `
            <thead></thead>
            <tbody>
                <tr class="flex justify-between items-start w-full text-xs text-gray-700">
                    <td class="font-medium">
                       ${label}
                    </td>
                    <td class="font-semibold">
                      ${numberFormat(amount)}
                    </td>
                </tr>
            </tbody>
        `

    externalTooltipHandler(context, innerHTML)
}

const barChartData = computed(() => {
    if (!props.varianceData) {
        return null
    }

    const colors = ['#95ADEE', '#C33D69', '#2EA597', '#7F56D9', '#E07941']

    return {
        labels: [props.startDate, props.endDate],
        datasets: props.varianceData.map((variance, idx) => {
            const colorIdx = idx > colors.length - 1 ? idx % colors.length : idx

            return {
                label: variance.item.description,
                barThickness: 50,
                backgroundColor: colors[colorIdx],
                data: [variance.start_amount, variance.end_amount],
            }
        }),
    }
})

const customPlugins = computed(() => ({
    beforeInit: function (chart) {
        const originalFit = chart.legend.fit

        chart.legend.fit = function () {
            originalFit.bind(chart.legend)()
            this.width += 120
        }
    },
}))

const barChartOptions = computed(() => ({
    plugins: {
        legend: {
            position: 'right',
            align: 'end',
            labels: {
                boxWidth: 12,
            },
        },
        tooltip: {
            enabled: false,
            position: 'nearest',
            external: tooltipHandler,
        },
        corsair: {
            horizontal: false,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
            ticks: {
                display: false,
            },
            border: {
                display: false,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        y: {
            stacked: true,
            border: {
                display: false,
            },
            beginAtZero: true,
            max: ({ chart }) => {
                let max = 5

                const maxData = []
                chart.data.datasets.forEach((dataset) => {
                    dataset.data.forEach((data, idx) => {
                        if (maxData[idx]) {
                            maxData[idx] = data + maxData[idx]
                        } else {
                            maxData[idx] = data
                        }
                    })
                })

                const datasetMax = Math.max(...maxData)
                if (datasetMax === 5) {
                    max = 6
                } else {
                    max = datasetMax > max ? datasetMax : max
                }

                return Math.max(chart.options.scales.y.suggestedMax, max)
            },
            ticks: {
                color: '#667085',
                count: 6,
                padding: 8,
                callback: function (val) {
                    return getShortNumber(val)
                },
            },
            grid: {
                drawBorder: false,
                color: '#F2F4F7',
            },
        },
    },
}))
</script>
