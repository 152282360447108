<template>
    <base-modal
        @modal-close="closeModal"
        :show="hasFile"
        size="auto"
        container-class="min-h-[90%]"
        class="my-5 max-h-full min-h-[85vh] overflow-auto !rounded-none !p-0"
    >
        <template #backdrop>
            <div
                v-if="state.activeIndex > 0"
                class="fixed left-20 top-1/2 flex h-16 w-16 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white"
                @click="onPrevious"
            >
                <base-icon
                    size="3xl"
                    name="line-icons:arrows:chevron-left"
                    variant="primary"
                    class="mr-1"
                />
            </div>
            <div
                v-if="state.activeIndex < previewsLength - 1"
                class="fixed right-20 top-1/2 flex h-16 w-16 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white"
                @click="onNext"
            >
                <base-icon
                    size="3xl"
                    name="line-icons:arrows:chevron-right"
                    variant="primary"
                    class="ml-1"
                />
            </div>
        </template>
        <template #container>
            <template v-if="isImage">
                <base-img
                    :src="activePreview.path"
                    :alt="activePreview.name"
                    container-class="w-[450px] select-none"
                />
            </template>
            <template v-else-if="isPdf">
                <base-object
                    :url="activePreview.path"
                    type="application/pdf"
                    width="1000px"
                    height="100%"
                    class="flex select-none items-center justify-center"
                    container-class="w-full min-w-112 h-full"
                >
                    <h5
                        class="text-center"
                        v-html="
                            $t('documentExtraction.list.unableToDisplayPDF', {
                                link: activePreview.path,
                            })
                        "
                    />
                </base-object>
            </template>
        </template>
    </base-modal>
</template>

<script setup>
import { computed } from 'vue'

const emit = defineEmits(['modal-close'])

const props = defineProps({
    previewFiles: {
        type: Array,
        required: false,
    },
})

const previewsLength = computed(() => {
    if (!props.previewFiles) {
        return 0
    }

    return props.previewFiles.length
})

const hasFile = computed(() => !!activePreview.value)

const state = reactive({
    activeIndex: null,
})

watch(
    () => props.previewFiles,
    (values) => {
        state.activeIndex = values === null ? null : 0
    }
)

const activePreview = computed(() => {
    if (state.activeIndex === null) {
        return null
    }

    return props.previewFiles[state.activeIndex]
})

const isImage = computed(() => {
    const fileMime = activePreview.value?.mime
    return fileMime === 'jpg' || fileMime === 'png' || fileMime === 'jpeg'
})

const isPdf = computed(() => {
    const fileMime = activePreview.value?.mime
    return fileMime === 'pdf'
})

const closeModal = () => {
    state.activeIndex = null
    emit('modal-close')
}

const onPrevious = () => {
    state.activeIndex--
}

const onNext = () => {
    state.activeIndex++
}
</script>
